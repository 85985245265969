import Env from "./Env";

export default class Config {
    public static BASE_URL = Config.getUrl() + 'api/';
    public static BASE_IMAGENES = Config.getUrl() + 'img/';
    public static BASE_PDFS = Config.getUrl() + 'pdfs/';

    public static BASE_IMAGENES_TIPOS = Config.BASE_IMAGENES + 'tipos/';
    public static BASE_IMAGENES_PRODUCTOS = Config.BASE_IMAGENES + 'productos/';
    public static ANALYTICS = 'UA-139856403-1';

    public static VERSION = '1.0';

    public static getUrl() {
        switch (process.env.REACT_APP_ENV) {
            case Env.STAGING:
                return 'http://aiscan-backend.cuatroochenta.com/';
            case Env.PROD:
                return 'https://backend-calculadora.aiscan.com/';
            default:
                return  'http://aiscan-symfony-local.com/';
        }
    }

};
