import React from 'react';
import {WrappedFieldProps} from "redux-form";

export interface FormInputProps extends WrappedFieldProps {
    id: string;
    type: string;
}

export default class RenderField extends React.Component<FormInputProps> {

    public render = () => {
        const { input, type, id } = this.props;

        switch (type) {
            case 'checkbox':
                return (
                    <input type="checkbox"{...input}  className="form-check-input" id={id}/>
                );
            default:
                return (
                    <input type={type} {...input} id={id} className="form-control"/>
                );

        }
    }

};
