import * as React from 'react';
import {Component, ReactNode} from 'react';
import {InfoProducto, Producto} from "../../model/Producto";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_VALOR_LLENADO, TR_VALOR_LLENADO_DESCRIPCION, TR_VALOR_LLENADO_SUBTITULO,
} from "../../I18n/constants";
import ProductoValorLlenadoGrafica from "./ProductoValorLlenadoGrafica";

interface ProductoValorLlenadoProps {
    producto: Producto,
    seccion: number,
    ampliacion: number
}


class ProductoValorLlenado extends Component<ProductoValorLlenadoProps> {

    public renderGraficas(): ReactNode {
        let i = 0;
        const versionesMismaAltura: InfoProducto[] = this.props.producto.versions.filter(
            (producto) =>
                producto.height === this.props.producto.info.height && producto.section >= this.props.seccion &&
                Math.ceil(100 * this.props.seccion / producto.section ) < 100
        );
        const indexCurrentProduct = versionesMismaAltura.findIndex((producto) => producto.ref === this.props.producto.info.ref );
        const versionesMostrar = [] as InfoProducto[];

        if (indexCurrentProduct === -1) {
            versionesMostrar.push(this.props.producto.info)
            if (versionesMismaAltura[0]) {
                versionesMostrar.push(versionesMismaAltura[0])
            }
            if (versionesMismaAltura[1]) {
                versionesMostrar.push(versionesMismaAltura[1])
            }
        }
        else if ( indexCurrentProduct === 0) {
            versionesMostrar.push(versionesMismaAltura[0])
            if (versionesMismaAltura[1]) {
                versionesMostrar.push(versionesMismaAltura[1])
            }
            if (versionesMismaAltura[2]) {
                versionesMostrar.push(versionesMismaAltura[2])
            }
        } else if (versionesMismaAltura[indexCurrentProduct - 1] && versionesMismaAltura[indexCurrentProduct + 1]) {
            versionesMostrar.push(versionesMismaAltura[indexCurrentProduct - 1])
            versionesMostrar.push(versionesMismaAltura[indexCurrentProduct])
            versionesMostrar.push(versionesMismaAltura[indexCurrentProduct + 1])
        } else {
            if (versionesMismaAltura[indexCurrentProduct - 2]) {
                versionesMostrar.push(versionesMismaAltura[indexCurrentProduct - 2])
            }
            if (versionesMismaAltura[indexCurrentProduct - 1]) {
                versionesMostrar.push(versionesMismaAltura[indexCurrentProduct - 1])
            }
            versionesMostrar.push(versionesMismaAltura[indexCurrentProduct])
        }


        return versionesMostrar.map( (version) => {
            if (i < 3) {
                i++;
                const porcenjeRelleno = Math.ceil(100 * this.props.seccion / version.section ) - this.props.ampliacion;
                return (
                    <div className={'col-12 mt-4 col-md-4'}  key={version.id}>
                        <ProductoValorLlenadoGrafica
                            width={version.width}
                            height={version.height}
                            porcenjeRelleno={porcenjeRelleno < 0 ? 0 : porcenjeRelleno}
                        />
                    </div>
                );
            }
            return null
        });
    }

    public render(): ReactNode {
        if (this.props.seccion) {
            return (
                <div id="product-value" className="card">
                    <div className="card-body">
                        <div className="product-section">
                            <h2>
                                {I18nUtils.tr(TR_VALOR_LLENADO)}<br/>
                                <small className="text-muted">{I18nUtils.tr(TR_VALOR_LLENADO_SUBTITULO)}</small>
                            </h2>
                            <p>{I18nUtils.tr(TR_VALOR_LLENADO_DESCRIPCION)}</p>
                            <div className="row mt-4" id="graficasValoresLlenado">
                                {this.renderGraficas()}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null
    }

}

export default ProductoValorLlenado;