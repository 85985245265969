import * as React from 'react';
import {Component, ReactNode} from 'react';
import LoadingView from "../../base/loading/LoadingView";
import {Folder, Proyecto} from "../../model/Proyecto";
import Collapse from "react-bootstrap/Collapse";
import TaskDeleteFolder from "../../ws/proyectos/TaskDeleteFolder";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_NOMBRE_NUEVA_CARPETA} from "../../I18n/constants";
import TaskCreateFolder from "../../ws/proyectos/TaskCreateFolder";
import Productos from "./Productos";

interface CarpetasProps {
    idProyecto: number,
    carpetas: Folder[],
    actualizaEstructura: (proyectos: Proyecto[]) => void
}

class Carpetas extends Component<CarpetasProps> {

    public state = { deletingCarpeta: 0, loadingNew: false, foldersOpened: [] as number[], nuevaCarpeta: ''};

    public anyadeCarpeta(): void {
        if (this.state.nuevaCarpeta !== '') {
            this.setState({loadingNew: true});
            new TaskCreateFolder(this.props.idProyecto, this.state.nuevaCarpeta)
                .onSuccess((projects) => {
                    this.setState({projects, loadingNew: false, nuevaCarpeta: ''});
                    this.props.actualizaEstructura(projects) 
                })
                .onFail(() => this.setState({loadingNew: false}))
                .execute()
        }
    }

    public eliminaCarpeta(id: number): void {
        this.setState({deletingCarpeta: id});
        new TaskDeleteFolder(id)
            .onSuccess((projects) => {
                this.setState({projects, deletingCarpeta: 0});
                this.props.actualizaEstructura(projects)
            })
            .onFail(() => this.setState({deletingCarpeta: 0}))
            .execute()
    }

    public onClickCarpeta(opened: boolean, id: number) {
        if (opened) {
            const carpetas = this.state.foldersOpened.find((idFolder: number) => idFolder !== id);
            this.setState({foldersOpened: [this.state.foldersOpened, carpetas]})
        }
        else {
            this.setState({foldersOpened: [...this.state.foldersOpened, id]})
        }
    }

    public render(): ReactNode {

        return (
            <div className="listado-carpetas-proyecto">
                {
                    this.props.carpetas.map((carpeta) => {
                        const opened = !!this.state.foldersOpened.find((id) => id === carpeta.id);
                        return (
                            <div className="card" key={carpeta.id}>
                                <div>
                                    <LoadingView loading={this.state.deletingCarpeta === carpeta.id}/>
                                    <div className="card-header" aria-expanded={opened} onClick={() => { this.onClickCarpeta(opened, carpeta.id) }}>
                                        <i className="material-icons icon-collapse">details</i>
                                        {carpeta.name}
                                        <i className="material-icons delete right" onClick={() => this.eliminaCarpeta(carpeta.id)}>delete</i>
                                    </div>
                                    <Collapse in={opened}>
                                        <div className="card-body">
                                            <Productos productos={carpeta.products} actualizaEstructura={(proyectos) => this.props.actualizaEstructura(proyectos)}/>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        );
                    })
                }
                <div className="card">
                    <div className="card-header">
                        <LoadingView loading={this.state.loadingNew}/>
                        <h5 className="mb-0">
                            <input
                                className="form-control" type="text"
                                placeholder={I18nUtils.tr(TR_NOMBRE_NUEVA_CARPETA)}
                                value={this.state.nuevaCarpeta}
                                onChange={(e) => this.setState({nuevaCarpeta: e.target.value})}
                            />
                            <i className="material-icons add-project" onClick={() => this.anyadeCarpeta()}>add_circle_outline</i>
                        </h5>
                    </div>
                </div>
            </div>
        );

    }

}

export default Carpetas;