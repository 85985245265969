import * as React from 'react';
import {Component, ReactNode} from 'react';
import {InfoProducto, Producto} from "../../model/Producto";
import i18next from "i18next";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_ALTO,
    TR_ANCHO,
    TR_REFERENCIA,
    TR_VERSIONES_PRODUCTOS, TR_VERSIONES_PRODUCTOS_DESCRIPCION,
} from "../../I18n/constants";
import {Link} from "react-router-dom";
import {generateRoute} from "../../utils/Router";
import {ROUTE_PRODUCTO} from "../../routing/Routes";

interface ProductoVersionesProps {
    producto: Producto,
    seccion: number
}


class ProductoVersiones extends Component<ProductoVersionesProps> {

    public render(): ReactNode {

        const versiones = this.props.producto.versions.filter((version) => version.section >= this.props.seccion);
        return (
            <div id="product-version" className="card">
                <div className="card-body">
                    <div className="product-section">
                        <h2 className="mb-3">
                            { I18nUtils.tr(TR_VERSIONES_PRODUCTOS) }<br/>
                        </h2>
                        <p className="text-muted">{I18nUtils.tr(TR_VERSIONES_PRODUCTOS_DESCRIPCION)}</p>
                        <table className="table tablaVersiones">
                            <tbody>
                            <tr>
                                <th scope="row">{I18nUtils.tr(TR_REFERENCIA)}</th>
                                <th>{I18nUtils.tr(TR_ANCHO)}</th>
                                <th>{I18nUtils.tr(TR_ALTO)}</th>
                            </tr>
                            {
                                Object.keys(versiones).map((key) => {
                                    const producto: InfoProducto = versiones[key];
                                    return (
                                        <tr key={producto.id} className={this.props.producto.info.ref === producto.ref ? 'referenciaDestacada' : ''}>
                                            <td scope="row">
                                                <Link to={generateRoute(ROUTE_PRODUCTO, {producto: producto.ref, locale: i18next.language})}>
                                                    {producto.ref}
                                                </Link>
                                            </td>
                                            <td>{producto.width}</td>
                                            <td>{producto.height}</td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

export default ProductoVersiones;