import * as React from 'react';
import {Component, ReactNode} from 'react';
import {Producto} from "../../model/Producto";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_DESCARGAR_PDF, TR_GRAFICA_PRODUCTO, TR_GUARDADO, TR_GUARDAR_PROYECTO, TR_INFORMACION,
    TR_VALOR_LLENADO, TR_VERSIONES_PRODUCTOS
} from "../../I18n/constants";
import Config from "../../Config";
import ModalesActions from "../Modales/ModalesActions";
import AuthManager from "../../utils/AuthManager";

interface ProductoCabeceraProps {
    producto: Producto,
    fijarCabecera: boolean,
    marginTopProducto: number,
    seccion: number,
    openProyectModal: () => void,
    productoGuardado: boolean,
}


class ProductoCabecera extends Component<ProductoCabeceraProps> {

    public state = { contenedorActual: 'product-info', scrolling: false};

    public componentDidMount(): void {
        window.addEventListener('scroll', this.handleScroll);
    }

    public componentWillUnmount(): void {
        window.removeEventListener("scroll", this.handleScroll);
    }

    public handleScroll = () => {
        if (!this.state.scrolling) {
            const productHeader: HTMLElement | null = document.getElementById('product-header');
            const productVersion: HTMLElement | null = document.getElementById('product-version');
            const productChart: HTMLElement | null = document.getElementById('product-chart');
            const productValue: HTMLElement | null = document.getElementById('product-value');
            const scrolly = window.pageYOffset + 10 + (!!productHeader ? productHeader.offsetHeight : 0);
            if (!!productValue && scrolly >= productValue.offsetTop) {
                if (this.state.contenedorActual !== 'product-value') {
                    this.setState({contenedorActual: 'product-value'})
                }
            } else if (!!productChart && scrolly >= productChart.offsetTop) {
                if (this.state.contenedorActual !== 'product-chart') {
                    this.setState({contenedorActual: 'product-chart'})
                }
            } else if (!!productVersion && scrolly >= productVersion.offsetTop) {
                if (this.state.contenedorActual !== 'product-version') {
                    this.setState({contenedorActual: 'product-version'})
                }
            } else {
                if (this.state.contenedorActual !== 'product-info') {
                    this.setState({contenedorActual: 'product-info'})
                }
            }
        }
    }

    public goTo(idContenedor: string): void {
        const contenedor: HTMLElement | null = document.getElementById(idContenedor);
        if (!!contenedor) {
            let scrollTo = contenedor.offsetTop - this.props.marginTopProducto - 10;
            if (!this.props.fijarCabecera) {
                const productHeader: HTMLElement | null = document.getElementById('product-header');
                if (!!productHeader) {
                    scrollTo -= productHeader.offsetHeight;
                }
            }
            window.scrollTo({behavior: 'smooth', top: scrollTo});
            this.setState({contenedorActual: idContenedor, scrolling: true})
            setTimeout(() => this.setState({scrolling: false}), 3000);
        }
    }

    public componentDidUpdate(prevProps: Readonly<ProductoCabeceraProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.producto !== prevProps.producto) {
            this.setState({contenedorActual: 'product-info'});
        }
    }

    public renderLinkTab(idContenedor: string, titulo: string): ReactNode {
        return (
            <li className="nav-item">
                <a className={`nav-link js-scroll-trigger ${this.state.contenedorActual === idContenedor ? 'active' : ''}`}
                   onClick={() => this.goTo(idContenedor)}
                >
                    { I18nUtils.tr(titulo) }
                </a>
            </li>
        );
    }


    public render(): ReactNode {
        return (
            <header id="product-header" className={this.props.fijarCabecera ? 'fixed-top' : ''}>
                <div className="container">
                    <div className="product-header-info">
                        <div className="d-flex flex-column pt-3">
                            <div className="d-flex align-items-baseline">
                                <h2 className="product-name">{ this.props.producto.info.ref }</h2>
                                <h3 className="product-type ml-2"><span>- </span>{ this.props.producto.info.name }</h3>
                            </div>
                            <p className="text-muted p-0">{ this.props.producto.info.norma }</p>
                        </div>
                        <div className="botoneras-producto">
                            {
                                this.props.producto.info.pdf ?
                                    <a href={Config.BASE_PDFS + this.props.producto.info.pdf} target="_blank" className="btn btn-sm btn-light d-none d-md-block btn-save mt-2">
                                        { I18nUtils.tr(TR_DESCARGAR_PDF) }
                                    </a> : ''
                            }
                            <div className="save-icon d-md-none" onClick={() => window.open(Config.BASE_PDFS + this.props.producto.info.pdf)}>
                                <i className="material-icons">get_app</i>
                            </div>
                            <a className={`btn btn-sm d-none d-md-block btn-save mt-2 ${this.props.productoGuardado ? 'btn-primary' : 'btn-light'}`}
                               onClick={() => AuthManager.isLogged() ? this.props.openProyectModal() : ModalesActions.openLogin.asConnectedAction() }
                            >
                                { I18nUtils.tr(this.props.productoGuardado ? TR_GUARDADO : TR_GUARDAR_PROYECTO) }
                            </a>
                            <div className="save-icon d-md-none">
                                <i className="material-icons">save</i>
                            </div>
                        </div>
                    </div>
                    <ul className="nav nav-tabs" id="product-nav">
                        { this.renderLinkTab('product-info', TR_INFORMACION) }
                        { this.renderLinkTab('product-version', TR_VERSIONES_PRODUCTOS) }
                        { this.renderLinkTab('product-chart', TR_GRAFICA_PRODUCTO) }
                        { this.props.seccion > 0 ? this.renderLinkTab('product-value', TR_VALOR_LLENADO) : null}
                    </ul>
                </div>
            </header>
        );
    }

}

export default ProductoCabecera;