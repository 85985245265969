import * as React from 'react';
import {Component, ReactNode} from 'react';
import {InfoProducto, Producto} from "../../model/Producto";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_GRAFICA_PRODUCTO, TR_GRAFICA_PRODUCTO_DESCRIPCION, TR_GRAFICA_PRODUCTO_SUBTITULO,
} from "../../I18n/constants";

import { Line } from 'react-chartjs-2';

interface ProductoGraficaProps {
    producto: Producto,
}


class ProductoGrafica extends Component<ProductoGraficaProps> {

    public getDataSets(version: InfoProducto, i: number) {

        const dataValues = [] as number[];

        const diferenciaPrimerTramo = version.grafica_valor1000 - version.grafica_valor1500;
        for (let tramo = 0; tramo < 5; tramo++) {
            dataValues.push(version.grafica_valor1000 - (tramo * diferenciaPrimerTramo / 5));
        }

        const diferenciaSegundoTramo = version.grafica_valor1500 - version.grafica_valor2000;
        for (let tramo = 0; tramo <= 5; tramo++) {
            dataValues.push(version.grafica_valor1500 - (tramo * diferenciaSegundoTramo / 5));
        }

        const color = ProductoGrafica.getColorLineVersions(i);

        return {
            label: version.width + 'x' + version.height,
            fill: false,
            lineTension: 0,
            backgroundColor: color,
            borderColor: color,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'round',
            pointBorderColor: color,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: color,
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: dataValues
        } as any;
    }

    public getDataSetsVersions(height: number) {
        const datasets = [] as any;

        const {producto} = this.props;
        if (producto.versions) {
            let i = 1;
            const versions = producto.versions.filter((version) => version.height === height);
            Object.keys(versions).map((key) => {
                datasets.push(this.getDataSets(versions[key], i));
                i++;
            });
        }

        return datasets;
    }

    public static getColorLineVersions(count): string {
        switch (count) {
            case 1:
                return 'rgba(75,192,192,1)';
            case 2:
                return 'rgba(200,0,0,1)';
            case 3:
                return 'rgba(255,131,0,1)';
            case 4:
                return 'rgba(0,200,0,1)';
            case 5:
                return 'rgba(249,255,0,1)';
            case 6:
                return 'rgba(123,0,144,1)';
            case 7:
                return 'rgba(0,6,255,1)';
            case 8:
                return 'rgba(255,0,175,1)';
            case 9:
                return 'rgba(183,68,22,1)';
            case 10:
                return 'rgba(192,192,75,1)';
        }
        return '';
    }



    public renderGrafica(height: number, labels: number[], options: {}, sizeCol: number): ReactNode {

        const data = {
            labels,
            datasets: this.getDataSetsVersions(height)
        };

        return (
            <div className={`col-12 lg-4 col-lg-${sizeCol}`} key={height}>
                <div className="wrapperGrafica">
                    <Line
                        data={data}
                        width={150}
                        height={150}
                        options={options}
                    />
                </div>
            </div>
        );

    }



    public renderGraficas(): ReactNode {

        const labels = [] as number[];

        for (let i = 1000; i <= 2000; i = i + 100 ) {
            labels.push(i)
        }

        const options = {
            scales: {
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'd (mm)'
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'CTA (N/m)'
                    }
                }]
            },
            maintainAspectRatio: true,
            legend: {
                display: true,
                fullWidth: false,
                labels: {
                    boxWidth: 5,
                    fontSize: 12,
                    usePointStyle: true
                }
            }
        }

        const alturas = [35, 60, 80, 100];
        const alturasDisponibles = [] as number[];
        alturas.map((altura) => {
            if (Object.keys(this.props.producto.versions.filter((version) => version.height === altura)).length > 0) {
                alturasDisponibles.push(altura);
            }
        });

        const sizeCol = alturasDisponibles.length === 4 ? 6 : 4;

        return (
            <>
                <div className="row mt-4 justify-content-md-center">
                    <div className={`col-12 lg-4 col-lg-${sizeCol}`}>
                        <div className="wrapperGrafica">
                            <Line
                                data={{labels, datasets: [this.getDataSets(this.props.producto.info, 1)]}}
                                width={150}
                                height={150}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row mt-4">
                    {
                        alturasDisponibles.map((altura) => {
                            return this.renderGrafica(altura, labels, options, sizeCol)
                        })
                    }
                </div>
            </>
        );

    }

    public render(): ReactNode {
        return (
            <div id="product-chart" className="card">
                <div className="card-body">
                    <div className="product-section">
                        <h2>
                            { I18nUtils.tr(TR_GRAFICA_PRODUCTO) }<br/>
                            <small className="text-muted">{ I18nUtils.tr(TR_GRAFICA_PRODUCTO_SUBTITULO) }</small>
                        </h2>
                        <p>{ I18nUtils.tr(TR_GRAFICA_PRODUCTO_DESCRIPCION) }</p>
                        { this.renderGraficas() }
                    </div>
                </div>
            </div>
        );
    }

}

export default ProductoGrafica;