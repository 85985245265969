import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {Proyecto} from "../../model/Proyecto";
import {generateRoute} from "../../utils/Router";
import i18next from "i18next";

export default class TaskCreateFolder extends BaseGenericRequest<{}, Proyecto[]> {

    private readonly name: string;

    public constructor(id: number, name: string) {
        super(METHOD.METHOD_POST, generateRoute(Urls.URL_CREATE_FOLDER, {project: id, locale: i18next.language}));
        this.name = name;
    }

    protected getRequest(): any {
        return {project_folder_form: { name: this.name }};
    }

}