import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";

interface TaskLoginRequest {
    user_reset_password_request_form: {
        email: string,
    }
}

export interface TaskLoginResponse {
    token: string
}

export default class TaskLostPassword extends BaseGenericRequest<TaskLoginRequest, TaskLoginResponse> {

    private readonly email: string;

    public constructor(email: string) {
        super(METHOD.METHOD_POST, Urls.URL_LOST_PASSWORD);
        this.email = email;
    }

    protected getRequest(): TaskLoginRequest {
        return {
            user_reset_password_request_form: {email: this.email},
        };
    }

}