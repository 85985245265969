import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {Proyecto} from "../../model/Proyecto";
import {generateRoute} from "../../utils/Router";
import i18next from "i18next";

export default class TaskDeleteFolder extends BaseGenericRequest<{}, Proyecto[]> {

    public constructor(id: number) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_DELETE_FOLDER, {folder: id, locale: i18next.language}));
    }

}