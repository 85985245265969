import * as React from 'react';
import createHistory from "history/createBrowserHistory";
import {routerMiddleware} from "react-router-redux";
import {ReduxStorageUtils} from "co-redux-builders";
import i18next from "i18next"
import {applyMiddleware, createStore, compose} from "redux";
import thunk from "redux-thunk";
import {Provider} from "react-redux";
import {History} from "history";
import CombinedReducers from "./redux/CombinedReducers";
import Routing from "./routing/Routing";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./res/scss/styles.scss";
import AuthManager from "./utils/AuthManager";
import Config from "./Config";
import ReactGA from 'react-ga';
import {ROUTE_HOME} from "./routing/Routes";
import {generateRoute} from "./utils/Router";

class App extends React.Component {

    protected history: History;

    constructor(props: any) {
        super(props);
        this.history = createHistory();
        AuthManager.initialized();
        ReactGA.initialize(Config.ANALYTICS);
        ReactGA.pageview(window.location.pathname);
        this.history.listen((location) => ReactGA.pageview(location.pathname));
    }

    public componentWillMount() {

        if (!window.location.href.includes(i18next.language)) {
            window.location.href = generateRoute(ROUTE_HOME, {locale: i18next.language});
        }

        const middleware = routerMiddleware(this.history);
        const composeEnhances = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'.toString()] as typeof compose || compose;
        ReduxStorageUtils.initialize(createStore(CombinedReducers, composeEnhances(applyMiddleware(thunk, middleware))));
    }

    public render() {
        return (
            <Provider store={ReduxStorageUtils.getStorage()}>
                <Routing history={this.history}/>
            </Provider>
        );
    }

}

export default App;