import * as React from 'react';
import {Component, ReactNode} from 'react';
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_COOKIES_ACEPTAR,
    TR_COOKIES_LEER_MAS,
    TR_COOKIES_TEXTO,
    TR_COOKIES_URL,
} from "../../I18n/constants";
import cookie from "react-cookies";

class CookiesAlert extends Component {

    public state = { display : false };

    public componentWillMount(): void {
        if (!cookie.load('AUTH_COOKIES')) {
            this.setState({display: true})
        }
        else {
            this.setCookie();
        }
    }

    public setCookie() {
        const expires = new Date();
        expires.setHours(expires.getHours() + 24 * 30 * 12);
        cookie.save('AUTH_COOKIES', '1', { path: '/', expires });
        this.setState({display: false})
    }

    public render(): ReactNode {
        if (this.state.display) {
            return (
                <div id="bar_cookies">
                    {I18nUtils.tr(TR_COOKIES_TEXTO)}
                    <a className="aceptar" onClick={() => this.setCookie()}>{I18nUtils.tr(TR_COOKIES_ACEPTAR)}</a>
                    <a className="leer-mas" href={I18nUtils.tr(TR_COOKIES_URL)}
                       target="_blank">{I18nUtils.tr(TR_COOKIES_LEER_MAS)}</a>
                </div>
            );
        }
        return <></>
    }

}


export default CookiesAlert;
