import Config from "../Config";

const BASE_URL = Config.BASE_URL;

export default class {

    public static URL_LOGIN = `${BASE_URL}login_check`;
    public static URL_REGENERATE_TOKEN = `${BASE_URL}user/refresh-token`;
    public static URL_LOST_PASSWORD = `${BASE_URL}user/password/reset/request`;
    public static URL_REGISTER = `${BASE_URL}register`;

    public static URL_SEARCH_PRODUCTS = `${BASE_URL}:locale/products/search`;
    public static URL_GET_PRODUCT = `${BASE_URL}:locale/product/:producto`;
    public static URL_GET_HEIGHT_PRODUCT = `${BASE_URL}:locale/height_product`;
    public static URL_GET_PROJECTS = `${BASE_URL}:locale/projects/list`;
    public static URL_CREATE_PROJECT = `${BASE_URL}:locale/project/create`;
    public static URL_DELETE_PROJECT = `${BASE_URL}:locale/project/:project/delete`;
    public static URL_DOWNLOAD_PROJECT = `${BASE_URL}:locale/project/:project/:file`;
    public static URL_ASK_FOR_BUDGET_PROJECT = `${BASE_URL}:locale/project/:project/budget`;
    public static URL_DELETE_FOLDER = `${BASE_URL}:locale/project/folders/:folder/delete`;
    public static URL_CREATE_FOLDER = `${BASE_URL}:locale/project/:project/folders/create`;
    public static URL_ADD_PRODUCT_PROJECT = `${BASE_URL}:locale/project/folders/:folder/products/add/:product`;
    public static URL_DELETE_PRODUCT_PROJECT = `${BASE_URL}:locale/project/folders/products/:product/delete`;

    public static URL_GET_MEASURES = `${BASE_URL}measures/list`;

}