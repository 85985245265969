import * as React from 'react';
import {Component, ReactNode} from 'react';
import {Producto} from "../../model/Producto";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_INFORMACION, TR_VIEW_LEGEND,
} from "../../I18n/constants";
import Config from "../../Config";
import ModalesActions from "../Modales/ModalesActions";

interface ProductoInformacionProps {
    producto: Producto,
}


class ProductoInformacion extends Component<ProductoInformacionProps> {


    public renderDescripcion(): ReactNode {

        if (this.props.producto.info.info) {
            const info = this.props.producto.info.info.split('\n');
            let i = 0;
            return info.map((parrafo) => {
                i++;
                if (parrafo.substr(0, 1) === '-') {
                    return (
                        <ul key={i}>
                            <li>{parrafo.substr(1).trim()}</li>
                        </ul>
                    )
                }
                return <p key={i}>{parrafo}</p>
            });
        }
        return <></>

    }

    public render(): ReactNode {
        const {producto} = this.props;
        return (
            <div id="product-info" className="card mt-0">
                <div className="card-body">
                    <div id="product-info" className="product-section">
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <h2>
                                    { I18nUtils.tr(TR_INFORMACION) }<br/>
                                </h2>
                                <div className="descriptionProducto">{ this.renderDescripcion() }</div>
                            </div>
                            <div className="col-12 col-md-5">
                                <img src={Config.BASE_IMAGENES_PRODUCTOS + producto.info.imagen} className="img-fluid"/>
                                <p className="view-legends-info"><a className="btn-open-lengend-icons" onClick={() => ModalesActions.openLegendIcons.asConnectedAction()}>{I18nUtils.tr(TR_VIEW_LEGEND)}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ProductoInformacion;