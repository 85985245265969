import {METHOD} from "co-generic-request";
import i18next from "i18next";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";
import {Producto} from "../../model/Producto";

export default class TaskGetProduct extends BaseGenericRequest<{}, Producto> {

    public constructor(producto: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_GET_PRODUCT, {producto, locale: i18next.language}) );
    }
}