import { InputData } from './interfaces';
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_ERROR_EMAIL_OBLIGATORIO,
    TR_ERROR_FIELD_EMAIL,
    TR_ERROR_FIELD_PASSWORD,
    TR_ERROR_NOMBRE_OBLIGATORIO,
    TR_ERROR_PASSWORD_OBLIGATORIO,
    TR_FORM_LEGAL_ERROR
} from "../../I18n/constants";

export const validate = (formValues: InputData) => {
    const errors: any = {};

    if (!formValues.name) {
        errors.name = I18nUtils.tr(TR_ERROR_NOMBRE_OBLIGATORIO);
    }
    if (!formValues.lastName) {
        errors.lastName = 'error';
    }

    if (!formValues.email) {
        errors.email = I18nUtils.tr(TR_ERROR_EMAIL_OBLIGATORIO);
    }
    else if (!formValues.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        errors.email = I18nUtils.tr(TR_ERROR_FIELD_EMAIL);
    }

    if (!formValues.password) {
        errors.password = I18nUtils.tr(TR_ERROR_PASSWORD_OBLIGATORIO);
    }
    else if (!formValues.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)) {
        errors.password = I18nUtils.tr(TR_ERROR_FIELD_PASSWORD);
    }

    if (!formValues.legal) {
        errors.legal = I18nUtils.tr(TR_FORM_LEGAL_ERROR);
    }

    return errors;

}