import {ReducerBuilder} from "co-redux-builders";
import BuscadorActions from "./BuscadorActions";
import {Buscador, Resultados} from "../../model/Buscador";
import TaskSearch from "../../ws/products/TaskSearch";
import StateManager from "../../utils/StateManager";
import {Medida} from "../../model/Medida";
import TaskGetMeasures from "../../ws/measures/TaskGetMeasures";
import TaskGetHeightProduct from "../../ws/products/TaskGetHeightProduct";
import Config from "../../Config";

interface InitialState {
    buscador: Buscador,
    buscando: boolean,
    resultados: Resultados,
    medidas: Medida[],
    alturas: []
}

const INITIAL_STATE = {
    buscador: {
        cables: [] as any,
        tipoBandeja: '',
        ampliacion: 30,
        altura: 60
    } as Buscador,
    buscando: false,
    resultados: {
        productos: {},
        seccion: 0,
    } as Resultados,
    medidas: [] as Medida[],
    alturas: [] as [],
    version: Config.VERSION
};

const buildState = (state: any) => {
    const newstate = {...state };
    StateManager.saveState(StateManager.FILTROS, newstate);
    return newstate;
};

export default ReducerBuilder.newBuilder(StateManager.getState(StateManager.FILTROS, INITIAL_STATE) as InitialState)

    .onEvent(BuscadorActions.actualizar, (oldState, payload) => buildState({ ...oldState, buscador: payload }))

    .onEvent(TaskSearch.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => buildState({ ...oldState, buscando: true }))
    .onEvent(TaskSearch.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => buildState({...oldState, resultados: payload, buscando: false }))
    .onEvent(TaskSearch.REDUX_ACTION_OBJECT_ON_FAIL, (oldState) => buildState({...oldState, resultados: {}, buscando: false }))
    .onEvent(TaskSearch.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => buildState({ ...oldState, buscando: false }))


    .onEvent(TaskGetMeasures.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({...oldState, medidas: payload, buscando: false}))
    .onEvent(TaskGetHeightProduct.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({...oldState, alturas: payload, buscando: false}))

    .build();
