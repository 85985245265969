import * as React from 'react';
import {Component, ReactNode} from 'react';
import i18next from "i18next"
import logo from '../../res/img/Logo_Aiscan_white.png';
import {
    TR_ACCESO_REGISTRO,
    TR_AISCAN,
    TR_CERRAR_SESION, TR_ENGLAND, TR_FRANCE, TR_MOROCCO, TR_PROYECTOS, TR_SPAIN, TR_VOLVER, TR_VOLVER_AL_LISTADO,
} from "../../I18n/constants";
import AuthManager from "../../utils/AuthManager";
import I18nUtils from "../../I18n/I18nUtils";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {ROUTE_HOME, ROUTE_PROYECTOS} from "../../routing/Routes";
import BuscadorReducer from "../../modules/Buscador/BuscadorReducer";
import {connect} from "react-redux";
import ModalesActions from "../../modules/Modales/ModalesActions";
import i18n from "i18next";
import {generateRoute} from "../../utils/Router";

const mapStateToProps = BuscadorReducer.autoMapToProps();

class ScreenHeader extends Component<RouteComponentProps & typeof mapStateToProps> {

    public render(): ReactNode {

        return (
            <nav className="navbar navbar-expand-lg navbar-light" id="mainNav">
                <div className="container">
                    {
                        this.props.history.location.pathname === ROUTE_PROYECTOS ?
                            <a onClick={this.props.history.goBack} className="nav-link back-listado">
                                <i className="material-icons">arrow_back</i>
                                <span className="align-top ml-2">{I18nUtils.tr(TR_VOLVER)}</span>
                            </a>
                            :
                            this.props.resultados && this.props.resultados.productos && Object.keys(this.props.resultados.productos).length > 0 ?
                                <Link to={generateRoute(ROUTE_HOME, {locale: i18next.language})}
                                      className="nav-link back-listado">
                                    <i className="material-icons">arrow_back</i>
                                    <span className="align-top ml-2">{I18nUtils.tr(TR_VOLVER_AL_LISTADO)}</span>
                                </Link> :
                                <></>
                    }
                    <Link to={generateRoute(ROUTE_HOME, {locale: i18next.language})}
                          className="navbar-brand js-scroll-trigger">
                        <img src={logo} className="img-fluid" alt={I18nUtils.tr(TR_AISCAN)}/>
                    </Link>
                    {
                        AuthManager.isLogged() ?
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link to={generateRoute(ROUTE_PROYECTOS, {locale: i18next.language})}
                                          className="nav-link js-scroll-trigger">{I18nUtils.tr(TR_PROYECTOS)}</Link>
                                </li>
                                <li key="logoutLink" className="nav-item ml-4">
                                    <a className="nav-link login" onClick={AuthManager.logout}>
                                        <i className="material-icons">lock</i>
                                        <span className="align-top ml-2">{I18nUtils.tr(TR_CERRAR_SESION)}</span>
                                    </a>
                                </li>
                            </ul> :
                            <a className="nav-link login" onClick={() => ModalesActions.openLogin.asConnectedAction()}>
                                <i className="material-icons">account_circle</i>
                                <span className="align-top ml-2">{I18nUtils.tr(TR_ACCESO_REGISTRO)}</span>
                            </a>
                    }
                    <div>
                        <select value={i18next.language}  className="form-control locale-selector" onChange={(e) => {i18n.changeLanguage(e.target.value)}}>
                            <option value="es-ES">{I18nUtils.tr(TR_SPAIN)}</option>
                            <option value="en-EN">{I18nUtils.tr(TR_ENGLAND)}</option>
                            <option value="fr-FR">{I18nUtils.tr(TR_FRANCE)}</option>
                            <option value="fr-MA">{I18nUtils.tr(TR_MOROCCO)}</option>
                        </select>
                    </div>
                </div>
            </nav>
        );
    }

}


export default withRouter(connect(mapStateToProps)(ScreenHeader) as any) as React.ComponentType<{}>;
