import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {Buscador} from "../../model/Buscador";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";
import i18next from "i18next";


export default class TaskSearch extends BaseGenericRequest<{}> {

    private readonly buscador: Buscador;

    public constructor(buscador: Buscador) {
        super(METHOD.METHOD_POST, generateRoute(Urls.URL_SEARCH_PRODUCTS, {locale: i18next.language}));
        this.buscador = buscador;
    }

    protected getRequest(): any {
        return {products_search_form: this.buscador};
    }
}

