import * as React from 'react';
import {ReactNode} from 'react';
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_CERRAR,
    TR_TEXTO_LEGAL,
    TR_TEXTO_LEGAL_TITLE,
} from "../../I18n/constants";
import Modal from "react-bootstrap/Modal";
import BaseModal, {BaseModalProps} from "../../base/modal/BaseModal";

class ModalLegal extends BaseModal<BaseModalProps> {

    public render(): ReactNode {
        return (
            <Modal id="modal-legal" show={this.props.show} className="modal" dialogClassName="modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label={ I18nUtils.tr(TR_CERRAR) } onClick={() => this.props.onClose()}>
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="container no-gutters">
                            <div className="row">
                                <div className="col-12 modal-form">
                                    <h2 className="mb-3 text-primary">{I18nUtils.tr(TR_TEXTO_LEGAL_TITLE)}</h2>
                                    <div dangerouslySetInnerHTML={{__html: I18nUtils.tr(TR_TEXTO_LEGAL)}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

}

export default ModalLegal;

