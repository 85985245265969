import {InputValuesBuscador} from "./interfaces";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_ERROR_BANDEJA, TR_ERROR_NO_CABLES} from "../../../I18n/constants";

export const validate = (formValues: InputValuesBuscador) => {
    const errors: any = {};

    if (!formValues.ampliacion) {
        errors.ampliacion = 'error'
    }

    if (!formValues.tipoBandeja) {
        errors.tipoBandeja = I18nUtils.tr(TR_ERROR_BANDEJA);
    }

    if (!formValues.cables || (formValues.cables && formValues.cables.length === 0)) {
        errors.cables = I18nUtils.tr(TR_ERROR_NO_CABLES);
    }
    else {
        let algunCableCompleto = false;
        let errorCable = false;
        formValues.cables.map((cable) => {
            if (cable) {
                if (cable[0] && cable[1] > 0) {
                    algunCableCompleto = true;
                }
                else if (cable[0] > 0  || cable[1] > 0) {
                    errors.cables = '';
                    errorCable = true;
                }
            }
        });
        if (!algunCableCompleto && !errorCable) {
            errors.cables = I18nUtils.tr(TR_ERROR_NO_CABLES);
        }
    }

    return errors;

}