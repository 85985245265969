import I18n from './I18n';

export default class I18nUtils {

    public static tr(value: string): string {
        return I18n.t(value);
    }

    public static getLocale(): string {
        return I18n.language;
    }

    public static setLocale(locale: string): void {
        I18n.changeLanguage(locale);
    }
}