import * as i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsEs from './translations/translations.es.json';
import translationsEn from './translations/translations.en.json';
import translationsFr from './translations/translations.fr.json';

i18next
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: ['es-ES', 'en-EN', 'fr-FR', 'fr-MA'],
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        detection: {
            order: ['path'],
            lookupFromPathIndex: 0,
        },
        // Using simple hardcoded resources for simple example
        resources: {
            "es-ES": {
                translation: translationsEs,
            },
            "en-EN": {
                translation: translationsEn,
            },
            "fr-FR": {
                translation: translationsFr,
            },
            "fr-MA": {
                translation: translationsFr,
            },
        },
    });

i18next.on('languageChanged', (lng) => {
    window.location.href = location.origin + `/${lng}`;
});

export default i18next;