import React from 'react';
import {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form'
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ALTURA, TR_AMPLICACION, TR_ANYADIR_CABLES,
    TR_BANDEJA_CIEGA,
    TR_BANDEJA_PERFORADA, TR_BORRAR_FILTROS, TR_CABLE,
    TR_CANTIDAD, TR_CONDUCTORES_SECCION,
    TR_ELIMINAR, TR_FUTURAS_AMPLIACIONES, TR_INDICA_ALTURA,
    TR_INDICA_RESERVA,
    TR_MOSTRAR_PRODUCTOS,
    TR_REJILLA,
    TR_SELECCIONA_TIPO_BANDEJA,
    TR_SELECCIONA_TIPOS,
    TR_TIPO_BANDEJA, TR_TIPOS_CABLES
} from "../../../I18n/constants";
import {BuscadorFormProps, InputValuesBuscador} from "./interfaces";
import {validate} from "./ValidateForm";
import RenderError from "../../../base/form/RenderError";
import normalizeNumber from "../../../base/form/normalizeForm";
import {Buscador} from "../../../model/Buscador";
import BuscadorActions from "../BuscadorActions";
import {Cable} from "../../../model/Cable";
import TaskSearch from "../../../ws/products/TaskSearch";
import BuscadorReducer from "../BuscadorReducer";
import {ReducerBuilder} from "co-redux-builders";
import Config from "../../../Config";
import TaskGetHeightProduct from "../../../ws/products/TaskGetHeightProduct";

const mapStateToPropsGeneric = (state: any) => {
    return {
        formValues: state.form.BuscadorForm,
    }
};

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(mapStateToPropsGeneric, BuscadorReducer.autoMapToProps());

class BuscadorForm extends Component<BuscadorFormProps & typeof mapStateToProps> {

    public bandejaPorDefecto = 'rejilla';
    public state = {totalCables: 3, alturasBandeja: [], bandejaSeleccionada: [1, 0, 0]};

    public componentWillMount(): void {
        this.props.initialize(this.props.initialValues);
        if (this.props.initialValues.cables && this.props.initialValues.cables.length > 0) {
            this.setState({totalCables: this.props.initialValues.cables.length});
        }
        new TaskGetHeightProduct().onSuccess((producto) => {
            this.setState({alturasBandeja: this.props.alturas[this.bandejaPorDefecto]});
            // @ts-ignore
            this.props.change('altura', this.state.alturasBandeja[0].height);
            this.props.change('tipoBandeja', this.bandejaPorDefecto);
        }).onFail().execute();
    }

    public filtrarBandeja = (tipo, seleccionado, e) => {
        this.setState({alturasBandeja: this.props.alturas[tipo]});
        this.setState({bandejaSeleccionada: seleccionado});
    }

    private onSubmit = (values: InputValuesBuscador) => {
        const cables = values.cables.map((cable) => {
            return {id: cable[0], cantidad: cable[1]} as Cable;
        });
        const buscador = {
            cables,
            tipoBandeja: values.tipoBandeja,
            ampliacion: values.ampliacion,
            altura: values.altura
        } as Buscador;

        BuscadorActions.actualizar.asConnectedAction(buscador);
        new TaskSearch(buscador).execute();
    }

    public borrarFiltros(): void {
        this.setState({totalCables: 3})
        this.props.change('cables', []);
        this.props.change('tipoBandeja', '');
        this.props.change('ampliacion', 30);
        this.props.change('altura', '');
    }

    public eliminaCable(index): void {
        if (this.props.formValues && this.props.formValues.values) {
            this.props.change('cables', [...this.props.formValues.values.cables.slice(0, index), ...this.props.formValues.values.cables.slice(index + 1)]);
        }
        this.setState({totalCables: this.state.totalCables - 1});
    }

    public renderSeccionCables(): ReactNode {

        const secciones: ReactNode[] = [];
        for (let i = 0; i < this.state.totalCables; i++) {
            let errorDiametro = false;
            let errorCantidad = false;
            if (this.props.formValues && this.props.formValues.values && this.props.formValues.values.cables[i]) {
                const cable = this.props.formValues.values.cables[i];
                if (cable[0] > 0 && (!cable[1] || cable[1] === '')) {
                    errorCantidad = true;
                } else if (cable[1] > 0 && (!cable[0] || cable[0] === '')) {
                    errorDiametro = true;
                }
            }
            secciones.push(
                <div className="form-item" key={i}>
                    <h3 className="form-item-title">{I18nUtils.tr(TR_CABLE)} {i + 1}</h3>
                    <div className="form-fields">
                        <div className="form-group">
                            <label htmlFor="DiameterInput">{I18nUtils.tr(TR_CONDUCTORES_SECCION)}</label>
                            <Field type="number" placeholder="0"
                                   component="select" name={`cables[${i}][0]`}
                                   className={'form-control' + (errorDiametro ? ' errorField' : '')}
                            >
                                <option value=""/>
                                {
                                    Object.keys(this.props.medidas).map((index) => {
                                        return (
                                            <option value={this.props.medidas[index].id}
                                                    key={this.props.medidas[index].id}>
                                                {this.props.medidas[index].conductors_section}
                                            </option>
                                        )
                                    })
                                }
                            </Field>
                        </div>
                        <div className="form-group">
                            <label htmlFor="QuantityInput">{I18nUtils.tr(TR_CANTIDAD)}</label>
                            <Field type="number" placeholder="0"
                                   normalize={normalizeNumber}
                                   component="input" name={`cables[${i}][1]`}
                                   className={'form-control' + (errorCantidad ? ' errorField' : '')}
                            />
                        </div>
                        <button className="remove" onClick={(e) => {
                            e.preventDefault();
                            this.eliminaCable(i)
                        }}>
                            <i className="material-icons">remove_circle_outline</i>
                            <span className="align-top">{I18nUtils.tr(TR_ELIMINAR)}</span>
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className="filter-section cables-section">
                {secciones}
                <div className="form-item">
                    <div className="form-fields">
                        <button className="add" onClick={(e) => {
                            e.preventDefault();
                            this.setState({totalCables: this.state.totalCables + 1})
                        }}>
                            <i className="material-icons">add_circle</i><span
                            className="align-top">{I18nUtils.tr(TR_ANYADIR_CABLES)}</span>
                        </button>
                    </div>
                </div>
            </div>
        );

    }

    public renderTiposBandeja(): ReactNode {

        return (
            <div className="filter-section checkbox-images">
                <div className="form-item text-center">
                    <div className="form-group form-check">
                        <div className="wrap-tipo-bandeja">
                            <Field type="radio" checked={this.state.bandejaSeleccionada[0]} component="input"
                                   value="rejilla" name="tipoBandeja"
                                   className="form-check-input"
                                   onClick={(e) => this.filtrarBandeja('rejilla', [1, 0, 0], e)}/>
                            <img src={Config.BASE_IMAGENES + 'tipos/rejilla.jpg'} className="img-fluid"/>
                        </div>
                        <label className="form-check-label">{I18nUtils.tr(TR_REJILLA)}</label>
                    </div>
                </div>
                <div className="form-item text-center">
                    <div className="form-group form-check">
                        <div className="wrap-tipo-bandeja">
                            <Field type="radio" checked={this.state.bandejaSeleccionada[1]} component="input"
                                   value="ciega" name="tipoBandeja"
                                   className="form-check-input"
                                   onClick={(e) => this.filtrarBandeja('ciega', [0, 1, 0], e)}/>
                            <img src={Config.BASE_IMAGENES + 'tipos/ciega.jpg'} className="img-fluid"/>
                        </div>
                        <label className="form-check-label">{I18nUtils.tr(TR_BANDEJA_CIEGA)}</label>
                    </div>
                </div>
                <div className="form-item text-center">
                    <div className="form-group form-check">
                        <div className="wrap-tipo-bandeja">
                            <Field type="radio" checked={this.state.bandejaSeleccionada[2]} component="input"
                                   value="perforada" name="tipoBandeja"
                                   className="form-check-input"
                                   onClick={(e) => this.filtrarBandeja('perforada', [0, 0, 1], e)}/>
                            <img src={Config.BASE_IMAGENES + 'tipos/perforada.jpg'} className="img-fluid"/>
                        </div>
                        <label className="form-check-label">{I18nUtils.tr(TR_BANDEJA_PERFORADA)}</label>
                    </div>
                </div>
            </div>
        );

    }

    public render(): ReactNode {
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <h2>
                    {I18nUtils.tr(TR_TIPOS_CABLES)}<br/>
                    <small className="text-muted">{I18nUtils.tr(TR_SELECCIONA_TIPOS)}</small>
                </h2>
                <Field name="cables" component={RenderError}/>
                {this.renderSeccionCables()}
                <hr/>
                <div className="d-lg-flex justify-content-between pt-3">
                    <div className="d-block">
                        <h2>
                            {I18nUtils.tr(TR_TIPO_BANDEJA)}<br/>
                            <small className="text-muted">{I18nUtils.tr(TR_SELECCIONA_TIPO_BANDEJA)}</small>
                        </h2>
                        <Field name="tipoBandeja" component={RenderError}/>
                        {this.renderTiposBandeja()}
                    </div>
                    <hr/>
                    <div className="mt-3 mt-lg-0 ml-lg-3">
                        <div className="filter-section rangeslider-section">
                            <h2>
                                {I18nUtils.tr(TR_AMPLICACION)}<br/>
                                <small
                                    className="text-muted">{I18nUtils.tr(TR_INDICA_RESERVA)}<br/>{I18nUtils.tr(TR_FUTURAS_AMPLIACIONES)}
                                </small>
                            </h2>
                            <div className="form-item">
                                <div className="form-fields">
                                    <div className="form-group align-items-center">
                                        <div className="rangeslider">
                                            <Field type="range" component="input" name="ampliacion"
                                                   className="slider mr-3"/>
                                            <input id="RangeQuantity" type="text" className="form-control"
                                                   placeholder="0" readOnly={true}
                                                   value={this.props.formValues && this.props.formValues.values ? this.props.formValues.values.ampliacion : 50}
                                            />
                                            <label htmlFor="ReserveInput">%</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="filter-section d-flex justify-content-between py-3">
                            <h2>
                                {I18nUtils.tr(TR_ALTURA)}<br/>
                                <small className="text-muted">{I18nUtils.tr(TR_INDICA_ALTURA)}</small>
                            </h2>
                            <div className="form-item">
                                <div className="form-fields">
                                    <div className="form-group">
                                        <Field component="select" name="altura" className="form-control">
                                            {
                                                Object.keys(this.state.alturasBandeja).map((index) => {
                                                    return (
                                                        <option key={index}
                                                                value={this.state.alturasBandeja[index].height}>{this.state.alturasBandeja[index].height}</option>
                                                    )
                                                })
                                            }
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <button type="button" className="btn btn-light mr-3"
                            onClick={() => this.borrarFiltros()}>{I18nUtils.tr(TR_BORRAR_FILTROS)}</button>
                    <button type="submit" className="btn btn-sm btn-primary">
                        {I18nUtils.tr(TR_MOSTRAR_PRODUCTOS)}
                    </button>
                </div>
            </form>
        );
    }
}


export default reduxForm<InputValuesBuscador>({
    validate,
    form: 'BuscadorForm',
})(connect(mapStateToProps)(BuscadorForm) as any);
