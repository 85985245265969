import * as React from 'react';
import {Component, ReactNode} from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import LoadingView from "../../base/loading/LoadingView";
import {Producto} from "../../model/Producto";
import TaskGetProduct from "../../ws/products/TaskGetProduct";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_PRODUCTO_NO_ENCONTRADO,
    TR_TEXTO_LEGAL, TR_TEXTO_LEGAL_TITLE,
} from "../../I18n/constants";
import ProductoCabecera from "./ProductoCabecera";
import ProductoInformacion from "./ProductoInformacion";
import ProductoVersiones from "./ProductoVersiones";
import ProductoGrafica from "./ProductoGrafica";
import ProductoValorLlenado from "./ProductoValorLlenado";
import BuscadorReducer from "../Buscador/BuscadorReducer";
import {connect} from "react-redux";
import ModalAsignarProyecto from "./ModalAsignarProyecto";
import AuthManager from "../../utils/AuthManager";

interface DataFormRouterProps {
    producto: string;
}

export interface DataFormProps extends RouteComponentProps<DataFormRouterProps> { }

const mapStateToProps = BuscadorReducer.autoMapToProps();


class FichaProducto extends Component<DataFormProps & typeof mapStateToProps> {

    public state = { loading: true, producto: {} as Producto, encontrado: false, fijarCabecera: false, marginTopProducto: 0, openModalProyecto: false, productoGuardado: false};

    public componentWillMount(): void {
        this.getProduct();
    }

    public componentDidUpdate(prevProps: Readonly<DataFormProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (!this.state.loading && this.props.match.params.producto !== prevProps.match.params.producto) {
            this.setState({loading: true, encontrado: false, fijarCabecera: false, marginTopProducto: 0})
            this.getProduct();
        }
    }

    public getProduct() {
        new TaskGetProduct(this.props.match.params.producto)
            .onSuccess((producto) => this.setState({producto, encontrado: true, loading: false, productoGuardado: false}))
            .onFail(() => this.setState({encontrado: false, loading: false}))
            .execute()
    }

    public componentDidMount(): void {
        const body = document.getElementsByTagName("BODY")[0];
        body.classList.add('ficha-producto');
        window.addEventListener('scroll', this.handleScroll);
    }

    public componentWillUnmount(): void {
        const body = document.getElementsByTagName("BODY")[0];
        body.classList.remove('ficha-producto');
        window.removeEventListener("scroll", this.handleScroll);
    }

    public handleScroll = () => {
        const mainNav: HTMLElement | null = document.getElementById('mainNav');
        const productHeader: HTMLElement | null = document.getElementById('product-header');
        if (!!mainNav && !!productHeader) {
            if (window.pageYOffset > mainNav.offsetHeight) {
                this.setState({fijarCabecera: true, marginTopProducto: productHeader.offsetHeight});
            } else {
                this.setState({fijarCabecera: false, marginTopProducto: 0});
            }
        }
    }

    public render(): ReactNode {

        if (this.state.loading) {
            return (
                <div className="container">
                    <LoadingView loading={true}/>
                </div>
            );
        }
        else if (!this.state.encontrado) {
            return (
                <div className="container">
                    <div className="mb-4 mt-4">
                        { I18nUtils.tr(TR_PRODUCTO_NO_ENCONTRADO) }
                    </div>
                </div>
            );
        }

        return (
            <>
                <ProductoCabecera
                    producto={this.state.producto}
                    fijarCabecera={this.state.fijarCabecera}
                    marginTopProducto={this.state.marginTopProducto}
                    seccion={this.props.resultados.seccion}
                    openProyectModal={() => this.setState({openModalProyecto: true})}
                    productoGuardado={this.state.productoGuardado}
                />
                <section className="product" style={{marginTop: this.state.marginTopProducto}}>
                    <div className="container">
                        <ProductoInformacion producto={this.state.producto}/>
                        <ProductoVersiones producto={this.state.producto} seccion={this.props.resultados.seccion ? this.props.resultados.seccion : 0}/>
                        <ProductoGrafica producto={this.state.producto}/>
                        {
                            this.props.resultados.seccion > 0 ?
                            <ProductoValorLlenado producto={this.state.producto} seccion={this.props.resultados.seccion} ampliacion={this.props.buscador.ampliacion}/>
                            : <></>
                        }
                        <div id="product-info" className="card mt-0">
                            <div className="card-body">
                                <div id="product-info" className="product-section">
                                    <h2 className="mb-3">
                                        { I18nUtils.tr(TR_TEXTO_LEGAL_TITLE) }<br/>
                                    </h2>
                                    <div dangerouslySetInnerHTML={{__html: I18nUtils.tr(TR_TEXTO_LEGAL)}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    AuthManager.isLogged() ?
                        <ModalAsignarProyecto
                            show={this.state.openModalProyecto}
                            onClose={() => this.setState({openModalProyecto: false})}
                            idProducto={this.state.producto.info.id}
                            productoGuardado={() => this.setState({productoGuardado: true})}
                        /> :
                        <></>
                }
            </>
        );

    }

}

export default withRouter(connect(mapStateToProps)(FichaProducto)) as React.ComponentType<{}>;