import {ReducerBuilder} from "co-redux-builders";
import TaskRegister from "../../ws/register/TaskRegister";

interface InitialState {
    showMessageError: boolean,
    savedForm: boolean,
    loadingSendingData: boolean,
}

const INITAL_STATE: InitialState = {
    showMessageError: false,
    savedForm: false,
    loadingSendingData: false,
};


export default ReducerBuilder.newBuilder(INITAL_STATE)

    .onEvent(TaskRegister.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loadingSendingData: true}))
    .onEvent(TaskRegister.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({...oldState, savedForm: true}))
    .onEvent(TaskRegister.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({...oldState, showMessageError: true}))
    .onEvent(TaskRegister.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loadingSendingData: false}))

    .build();
