import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {Proyecto} from "../../model/Proyecto";
import {generateRoute} from "../../utils/Router";
import i18next from "i18next";

export default class TaskAddProduct extends BaseGenericRequest<{}, Proyecto[]> {

    private readonly longitude: string;
    private readonly observations: string;

    public constructor(folder: number, product: number, longitude: string, observations: string) {
        super(METHOD.METHOD_POST, generateRoute(Urls.URL_ADD_PRODUCT_PROJECT, {folder, product, locale: i18next.language}));
        this.longitude = longitude;
        this.observations = observations;
    }

    protected getRequest(): {} {
        return {longitude: this.longitude, observations: this.observations};
    }

}