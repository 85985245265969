import {ReducerBuilder} from "co-redux-builders";
import ModalesActions from "./ModalesActions";

const INITIAL_STATE = {
    openedLogin: false,
    openedRegister: false,
    openedPasswordLost: false,
    openedLegendIcons: false,
    openedModalLegal: false
};

const buildState = (state: any) => {
    return state;
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(ModalesActions.closeModals, (oldState) => buildState({ ...oldState, openedLogin: false, openedRegister: false, openedPasswordLost: false, openedLegendIcons: false, openedModalLegal: false }))

    .onEvent(ModalesActions.openLogin, (oldState, payload) => buildState({ ...oldState, openedLogin: true, openedRegister: false, openedPasswordLost: false, openedLegendIcons: false, openedModalLegal: false }))
    .onEvent(ModalesActions.openRegister, (oldState, payload) => buildState({ ...oldState, openedLogin: false, openedRegister: true, openedPasswordLost: false, openedLegendIcons: false, openedModalLegal: false }))
    .onEvent(ModalesActions.openPasswordLost, (oldState, payload) => buildState({ ...oldState, openedLogin: false, openedRegister: false, openedPasswordLost: true, openedLegendIcons: false, openedModalLegal: false }))
    .onEvent(ModalesActions.openLegendIcons, (oldState, payload) => buildState({ ...oldState, openedLogin: false, openedRegister: false, openedPasswordLost: false, openedLegendIcons: true, openedModalLegal: false }))
    .onEvent(ModalesActions.openModalLegal, (oldState, payload) => buildState({ ...oldState, openedLogin: false, openedRegister: false, openedPasswordLost: false, openedLegendIcons: false, openedModalLegal: true }))

    .build();
