import {ReduxStorageUtils} from "co-redux-builders";
import { reducer as FormReducer } from "redux-form";
import BuscadorReducer from "../modules/Buscador/BuscadorReducer";
import LoginReducer from "../modules/Login/LoginReducer";
import RegisterFormReducer from "../modules/Register/RegisterFormReducer";
import ModalesReducer from "../modules/Modales/ModalesReducer";

(FormReducer as any).reactotronKey = "form";

export default ReduxStorageUtils.combineReducers([
    /* GENERAL */
    FormReducer as any,
    LoginReducer,
    RegisterFormReducer,
    BuscadorReducer,
    ModalesReducer
]);
