import {METHOD} from "co-generic-request";
import i18next from "i18next";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";

export default class TaskGetHeightProduct extends BaseGenericRequest<{}, []> {

    public constructor() {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_GET_HEIGHT_PRODUCT, {locale: i18next.language}) );
    }
}