import * as React from 'react';
import {ReactNode} from 'react';
import I18nUtils from "../../I18n/I18nUtils";
import AuthManager from "../../utils/AuthManager";
import TaskLostPassword from "../../ws/login/TaskLostPassword";
import LoadingView from "../../base/loading/LoadingView";
import BaseModal, {BaseModalProps} from "../../base/modal/BaseModal";
import {
    TR_CERRAR,
    TR_ENVIADO_EMAIL_RECORDAR_CONTRASENYA, TR_ENVIAR,
    TR_ERROR_RECORDAR_CONTRASENYA,
    TR_FORM_EMAIL, TR_INDICA_DIRECCION, TR_REESTABLECER_CLAVE
} from "../../I18n/constants";
import Modal from "react-bootstrap/Modal";

class LostPasswordScreen extends BaseModal<BaseModalProps> {

    public state = { email: '', message: '', enviando: false };

    public componentDidMount(): void {
        if (AuthManager.isLogged()) {
            this.props.onClose();
        }
    }

    public recordarContrasenya() {
        if (this.state.email !== '') {
            this.setState({enviando: true})
            new TaskLostPassword(this.state.email)
                .onSuccess(() => this.setState({message: TR_ENVIADO_EMAIL_RECORDAR_CONTRASENYA, enviando: false}))
                .onFail(() => this.setState({message: TR_ERROR_RECORDAR_CONTRASENYA, enviando: false}))
                .execute();
        }
    }

    public render(): ReactNode {
        return (

            <Modal id="LoginModal" show={this.props.show} className="modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label={ I18nUtils.tr(TR_CERRAR) } onClick={() => this.props.onClose()}>
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                </div>
                <div className="modal-body p-0">
                    <div className="container no-gutters">
                        <div className="row">
                            <div className="col-12 modal-form">
                                <h2 className="modal-title">{ I18nUtils.tr(TR_REESTABLECER_CLAVE) }</h2>
                                <p className="lead text-muted mb-4">{ I18nUtils.tr(TR_INDICA_DIRECCION) }</p>
                                <div className={`error_login ${this.state.message === '' ? 'd-none' : ''}`}>
                                    { I18nUtils.tr(this.state.message) }
                                </div>
                                <LoadingView loading={this.state.enviando}/>
                                <form onSubmit={ (e) => { e.preventDefault(); this.recordarContrasenya() } }>
                                    <div className="form-group">
                                        <label htmlFor="DiameterInput">{ I18nUtils.tr(TR_FORM_EMAIL) }</label>
                                        <input type="email"
                                               className="form-control"
                                               id="Email"
                                               value={this.state.email}
                                               onChange={(e) => {this.setState({email: e.target.value, message: ''})}}
                                               onKeyPress={(e) => { if (e.key === 'Enter') { this.recordarContrasenya() }}}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-center mt-2">
                                        <button type="submit" className="btn btn-lg btn-primary">{ I18nUtils.tr(TR_ENVIAR) }</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

}

export default LostPasswordScreen;

