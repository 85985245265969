import * as React from 'react';
import {Component, ReactNode} from 'react';
import { connect } from 'react-redux';
import ModalesReducer from "./ModalesReducer";
import RegisterScreen from "../Register/RegisterScreen";
import LostPasswordScreen from "../Login/LostPasswordScreen";
import LoginScreen from "../Login/LoginScreen";
import ModalesActions from "./ModalesActions";
import ModalLegendIcons from "../Producto/ModalLegendIcons";
import ModalLegal from "../../components/screen/ModalLegal";

const mapStateToProps = ModalesReducer.autoMapToProps();
const mapActionsToProps = ModalesActions.autoMapToProps();

class Modales extends Component<typeof mapStateToProps & typeof mapActionsToProps> {

    public render(): ReactNode {

        return (
            <>
                <LoginScreen
                    show={ this.props.openedLogin }
                    onClose={ this.props.closeModals }
                    openRegister={ this.props.openRegister }
                    openPasswordLost={ this.props.openPasswordLost }
                />
                <RegisterScreen
                    show={ this.props.openedRegister }
                    onClose={ this.props.closeModals }
                    openLogin={ this.props.openLogin }
                />
                <LostPasswordScreen
                    show={ this.props.openedPasswordLost }
                    onClose={ this.props.closeModals }
                />
                <ModalLegendIcons
                    show={ this.props.openedLegendIcons }
                    onClose={ this.props.closeModals }
                />
                <ModalLegal
                    show={ this.props.openedModalLegal }
                    onClose={ this.props.closeModals }
                />
            </>
        );

    }

}

export default connect(mapStateToProps, mapActionsToProps)(Modales) as React.ComponentType<{}>;