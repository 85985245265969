import * as React from 'react';
import {ReactNode} from 'react';
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_CERRAR, TR_ICON_FOOD, TR_ICON_IN, TR_ICON_IN_OUT, TR_ICON_INDUSTRY, TR_ICON_OUT,
} from "../../I18n/constants";
import Modal from "react-bootstrap/Modal";
import BaseModal, {BaseModalProps} from "../../base/modal/BaseModal";
import iconIn from "../../res/img/icon-in.png"
import iconOut from "../../res/img/icon-out.png"
import iconInOut from "../../res/img/icon-in-out.png"
import iconIndustry from "../../res/img/icon-industry.png"
import iconFood from "../../res/img/icon-food.png"

class ModalLegendIcons extends BaseModal<BaseModalProps> {

    public render(): ReactNode {
        return (
            <Modal id="modal-icons-legend" show={this.props.show} className="modal" dialogClassName="modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label={ I18nUtils.tr(TR_CERRAR) } onClick={() => this.props.onClose()}>
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="container no-gutters">
                            <div className="row">
                                <div className="col-12 modal-form">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12">
                                            <div className="row">
                                                <img src={iconIn}/>
                                                <p>{I18nUtils.tr(TR_ICON_IN)}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <img src={iconOut}/>
                                            <p>{I18nUtils.tr(TR_ICON_OUT)}</p>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <img src={iconInOut}/>
                                            <p>{I18nUtils.tr(TR_ICON_IN_OUT)}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12">
                                            <img src={iconIndustry}/>
                                            <p>{I18nUtils.tr(TR_ICON_INDUSTRY)}</p>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <img src={iconFood}/>
                                            <p>{I18nUtils.tr(TR_ICON_FOOD)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

}

export default ModalLegendIcons;

