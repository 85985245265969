import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {Usuario} from "../../model/Usuario";
import BaseGenericRequest from "../BaseGenericRequest";

export interface TaskRegisterRequest {
    email: string,
    plainPassword: string,
    name: string,
    lastName: string,
}

export interface TaskRegisterResponse {
    token: string,
    message: string,
    user: Usuario
}

export default class TaskRegister extends BaseGenericRequest<TaskRegisterRequest, TaskRegisterResponse> {

    private readonly  userData: TaskRegisterRequest;

    public constructor(usuario: TaskRegisterRequest) {
        super(METHOD.METHOD_POST, Urls.URL_REGISTER);
        this.userData = {
            email: usuario.email,
            plainPassword: usuario.plainPassword,
            name: usuario.name,
            lastName: usuario.lastName,
        };
    }

    protected getRequest(): any {
        return {fos_user_registration_form: this.userData};
    }

}