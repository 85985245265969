import * as React from 'react';
import {Component, ReactNode} from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import LoadingView from "../../base/loading/LoadingView";
import AuthManager from "../../utils/AuthManager";
import {ROUTE_HOME} from "../../routing/Routes";
import TaskGetProjects from "../../ws/proyectos/TaskGetProjects";
import {Proyecto} from "../../model/Proyecto";
import Collapse from "react-bootstrap/Collapse";
import TaskDeleteProject from "../../ws/proyectos/TaskDeleteProject";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_MIS_PROYECTOS, TR_NOMBRE_NUEVO_PROYECTO} from "../../I18n/constants";
import TaskCreateProject from "../../ws/proyectos/TaskCreateProject";
import Carpetas from "./Carpetas";
import TaskDownloadProject from "../../ws/proyectos/TaskDownloadProject";
import TaskAskForBudgetProject from "../../ws/proyectos/TaskAskForBudgetProject";


class Proyectos extends Component<RouteComponentProps> {

    public state = { loading: true, projects: [] as Proyecto[], projectsOpened: [] as number[], nuevoProyecto: '', downloadingProject: 0, askingProject: 0, presupuestosSolicitados: []};

    public componentWillMount(): void {
        if (!AuthManager.isLogged()) {
            this.props.history.push(ROUTE_HOME)
        }
        else {
            new TaskGetProjects()
                .onSuccess((projects) => this.setState({projects, loading: false}))
                .onFail(() => this.props.history.push(ROUTE_HOME))
                .execute()
        }
    }

    public componentDidMount(): void {
        const body = document.getElementsByTagName("BODY")[0];
        body.classList.add('pagina-proyectos');
    }

    public componentWillUnmount(): void {
        const body = document.getElementsByTagName("BODY")[0];
        body.classList.remove('pagina-proyectos');
    }

    public anyadeProyecto(): void {
        if (this.state.nuevoProyecto !== '') {
            this.setState({loading: true});
            new TaskCreateProject(this.state.nuevoProyecto)
                .onSuccess((projects) => this.setState({projects, loading: false, nuevoProyecto: ''}))
                .onFail(() => this.setState({loading: false}))
                .execute()
        }
    }

    public eliminaProyecto(id: number): void {
        this.setState({loading: true});
        new TaskDeleteProject(id)
            .onSuccess((projects) => this.setState({projects, loading: false}))
            .onFail(() => this.setState({loading: false}))
            .execute()
    }

    public descargaProyecto(id: number, type: string): void {
        if (this.state.downloadingProject !== id) {
            this.setState({downloadingProject: id});
            new TaskDownloadProject(id, type)
                .onSuccess((result: any) => {
                    window.open(result.file);
                    this.setState({downloadingProject: 0})
                })
            .onFail(() => this.setState({downloadingProject: 0}))  
            .execute()
        }
    }

    public solicitaPresupuesto(id: number): void {
        if (this.state.askingProject !== id) {
            this.setState({askingProject: id});
            new TaskAskForBudgetProject(id)
                .onSuccess((result: any) => {
                    window.open(result.pdf, '_self');
                    this.setState({askingProject: 0, presupuestosSolicitados: [...this.state.presupuestosSolicitados, id]})
                })
                .onFail(() => this.setState({askingProject: 0}))
                .execute()
        }
    }

    public onClickProyecto(opened: boolean, id: number) {
        if (opened) {
            const proyectos = this.state.projectsOpened.find((idProyecto: number) => idProyecto !== id);
            this.setState({projectsOpened: [this.state.projectsOpened, proyectos]})
        }
        else {
            this.setState({projectsOpened: [...this.state.projectsOpened, id]})
        }
    }

    public renderListadoProyectos(): ReactNode {

        return (
            <div className="accordion" id="listado-proyectos">
                {
                    this.state.projects.map((proyecto) => {
                        const opened = !!this.state.projectsOpened.find((id) => id === proyecto.id);
                        const solicitado = this.state.presupuestosSolicitados.some((item) => proyecto.id === item);
                        return (
                            <div className="card" key={proyecto.id}>
                                <div className="card-header" aria-expanded={opened} onClick={() => { this.onClickProyecto(opened, proyecto.id) }}>
                                    <h5 className="mb-0">
                                        <i className="material-icons icon-collapse">details</i>
                                        {proyecto.name}
                                        <i className="material-icons right" onClick={() => this.eliminaProyecto(proyecto.id)}>delete</i>
                                        <i
                                            className="material-icons right"
                                            aria-disabled={this.state.downloadingProject === proyecto.id}
                                            onClick={(e) => {e.stopPropagation(); this.descargaProyecto(proyecto.id, 'pdf')}}
                                        >picture_as_pdf</i>
                                        <i
                                            className="material-icons right"
                                            aria-disabled={this.state.downloadingProject === proyecto.id}
                                            onClick={(e) => {e.stopPropagation(); this.descargaProyecto(proyecto.id, 'excel')}}
                                        >file_download</i>
                                        <span
                                            className={`right solicita-presupuesto ${solicitado ? 'solicitado' : ''}`}
                                            aria-disabled={this.state.askingProject === proyecto.id} onClick={(e) => {e.stopPropagation(); this.solicitaPresupuesto(proyecto.id)}}
                                        >{I18nUtils.tr(solicitado ? 'TR_ASKED' : 'TR_ASK_FOR_BUDGET')}</span>
                                    </h5>
                                </div>
                                <Collapse in={opened}>
                                    <div className="card-body">
                                        <Carpetas idProyecto={proyecto.id} carpetas={proyecto.folders} actualizaEstructura={(projects) => this.setState({projects})}/>
                                    </div>
                                </Collapse>
                            </div>

                        )
                    })
                }
                <div className="card">
                    <div className="card-header">
                        <h5 className="mb-0">
                            <input
                                className="form-control" type="text"
                                placeholder={I18nUtils.tr(TR_NOMBRE_NUEVO_PROYECTO)}
                                value={this.state.nuevoProyecto}
                                onChange={(e) => this.setState({nuevoProyecto: e.target.value})}
                            />
                            <i className="material-icons add-project" onClick={() => this.anyadeProyecto()}>add_circle_outline</i>
                        </h5>
                    </div>
                </div>
            </div>
        );

    }

    public render(): ReactNode {

        return (
            <div>
                <section className="projects-section">
                    <div className="container">
                        <div className="card mt-0">
                            <div className="card-body">
                                <h3>{I18nUtils.tr(TR_MIS_PROYECTOS)}</h3>
                                <LoadingView loading={this.state.loading}/>
                                {this.renderListadoProyectos()}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }

}

export default withRouter(Proyectos) as React.ComponentType<{}>;