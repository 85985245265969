import React from 'react';
import {WrappedFieldProps} from "redux-form";

const RenderError = (props: WrappedFieldProps) => {
    const { meta } = props;
    if (meta.touched && meta.error) {
        return (
            <div className="error-message mb-3">{meta.error}</div>
        );
    }
    else {
        return <></>;
    }
};

export default RenderError;