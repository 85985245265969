import * as  React from "react";
import {ReactNode} from "react";
import {RouterProps} from "react-router";
import {Route, Router} from "react-router-dom";
import ScreenHeader from "../components/screen/ScreenHeader";
import ScreenFooter from "../components/screen/ScreenFooter";
import Buscador from "../modules/Buscador/Buscador";
import {ROUTE_HOME, ROUTE_PRODUCTO, ROUTE_PROYECTOS} from "./Routes";
import Producto from "../modules/Producto/Producto";
import Proyectos from "../modules/Proyectos/Proyectos";
import Modales from "../modules/Modales/Modales";
import CookiesAlert from "../components/screen/CookiesAlert";

export default class Routing extends React.Component<RouterProps> {

    public render(): ReactNode {

        return(
            <Router history={this.props.history}>
                <div>

                    {/*HEADER*/}
                    <Route path="/" component={ScreenHeader} />


                    {/*CUSTOM*/}
                    <Route path={ROUTE_HOME} exact component={Buscador} />
                    <Route path={ROUTE_PRODUCTO} exact component={Producto}/>
                    <Route path={ROUTE_PROYECTOS} exact component={Proyectos} />


                    {/*FOOTER*/}
                    <Route path="/" component={ScreenFooter} />
                    <Route path="/" component={CookiesAlert} />

                    {/*MODALES*/}
                    <Route path="/" component={Modales} />

                </div>
            </Router>
        );
    }

}
