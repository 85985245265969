import {ReducerBuilder} from "co-redux-builders";
import LoginActions from "./LoginActions";
import TaskLogin from "../../ws/login/TaskLogin";

const INITAL_STATE = {
    username: '',
    password: '',
    loading: false,
    allowLogin: false,
    error: '',
};

const buildState = (state: any) => {
    return ({...state, allowLogin: state.username.length > 0 && state.password.length > 0, error: '' });
};

export default ReducerBuilder.newBuilder(INITAL_STATE)
    .onEvent(LoginActions.setUsername, (oldState, payload) => buildState({ ...oldState, username: payload.username || '' }))
    .onEvent(LoginActions.setPassword, (oldState, payload) => buildState({ ...oldState, password: payload.password || '' }))
    .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({...oldState, error: payload}))
    .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loading: true}))
    .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loading: false}))
    .build();
