export const TR_AISCAN = 'TR_AISCAN';
export const TR_DESARROLLADO_POR = 'TR_DESARROLLADO_POR';
export const TR_CERRAR_SESION = 'TR_CERRAR_SESION';
export const TR_ACCESO_REGISTRO = 'TR_ACCESO_REGISTRO';
export const TR_PROYECTOS = 'TR_PROYECTOS';
export const TR_TIMEOUT_MESSAGE = 'TR_TIMEOUT_MESSAGE';
export const TR_NO_NET_MESSAGE = 'TR_NO_NET_MESSAGE';
export const TR_UNAUTHORIZED_ERROR = 'TR_UNAUTHORIZED_ERROR';
export const TR_INTERNAL_SERVER_ERROR = 'TR_INTERNAL_SERVER_ERROR';
export const TR_TIPOS_CABLES = 'TR_TIPOS_CABLES';
export const TR_SELECCIONA_TIPOS = 'TR_SELECCIONA_TIPOS';
export const TR_CABLE = 'TR_CABLE';
export const TR_CONDUCTORES_SECCION = 'TR_CONDUCTORES_SECCION';
export const TR_MM = 'TR_MM';
export const TR_CANTIDAD = 'TR_CANTIDAD';
export const TR_ELIMINAR = 'TR_ELIMINAR';
export const TR_ANYADIR_CABLES = 'TR_ANYADIR_CABLES';
export const TR_TIPO_BANDEJA = 'TR_TIPO_BANDEJA';
export const TR_SELECCIONA_TIPO_BANDEJA = 'TR_SELECCIONA_TIPO_BANDEJA';
export const TR_REJILLA = 'TR_REJILLA';
export const TR_BANDEJA_CIEGA = 'TR_BANDEJA_CIEGA';
export const TR_BANDEJA_PERFORADA = 'TR_BANDEJA_PERFORADA';
export const TR_AMPLICACION = 'TR_AMPLICACION';
export const TR_INDICA_RESERVA = 'TR_INDICA_RESERVA';
export const TR_FUTURAS_AMPLIACIONES = 'TR_FUTURAS_AMPLIACIONES';
export const TR_ALTURA = 'TR_ALTURA';
export const TR_INDICA_ALTURA = 'TR_INDICA_ALTURA';
export const TR_BORRAR_FILTROS = 'TR_BORRAR_FILTROS';
export const TR_MOSTRAR_PRODUCTOS = 'TR_MOSTRAR_PRODUCTOS';
export const TR_ERROR_BANDEJA = 'TR_ERROR_BANDEJA';
export const TR_ERROR_NO_CABLES = 'TR_ERROR_NO_CABLES';
export const TR_ERROR_CABLES_DATOS = 'TR_ERROR_CABLES_DATOS';
export const TR_RESULTADOS_ENCONTRADOS = 'TR_RESULTADOS_ENCONTRADOS';
export const TR_RESULTADOS_ENCONTRADOS_NINGUNO = 'TR_RESULTADOS_ENCONTRADOS_NINGUNO';
export const TR_PAG_ANTERIOR = 'TR_PAG_ANTERIOR';
export const TR_PAG_SIGUIENTE = 'TR_PAG_SIGUIENTE';
export const TR_TIPO_BANDEJA_REJILLA = 'TR_TIPO_BANDEJA_REJILLA';
export const TR_TIPO_BANDEJA_CIEGA = 'TR_TIPO_BANDEJA_CIEGA';
export const TR_TIPO_BANDEJA_PERFORADA = 'TR_TIPO_BANDEJA_PERFORADA';
export const TR_YA_TIENES_CUENTA = 'TR_YA_TIENES_CUENTA';
export const TR_ENTRA_Y_CONSULTA = 'TR_ENTRA_Y_CONSULTA';
export const TR_REGISTRATE = 'TR_REGISTRATE';
export const TR_REGISTRARME = 'TR_REGISTRARME';
export const TR_CREA_UNA_CUENTA = 'TR_CREA_UNA_CUENTA';
export const TR_NOMBRE_APELLIDOS = 'TR_NOMBRE_APELLIDOS';
export const TR_NOMBRE = 'TR_NOMBRE';
export const TR_APELLDOS = 'TR_APELLDOS';
export const TR_HE_LEIDO_Y_ACEPTO = 'TR_HE_LEIDO_Y_ACEPTO';
export const TR_POLITICA_PRIVACIDAD = 'TR_POLITICA_PRIVACIDAD';
export const TR_URL_POLITICA_PRIVACIDAD = 'TR_URL_POLITICA_PRIVACIDAD';
export const TR_FORM_LEGAL_ERROR = 'TR_FORM_LEGAL_ERROR';
export const TR_ERROR_NOMBRE_OBLIGATORIO = 'TR_ERROR_NOMBRE_OBLIGATORIO';
export const TR_ERROR_EMAIL_OBLIGATORIO = 'TR_ERROR_EMAIL_OBLIGATORIO';
export const TR_ERROR_PASSWORD_OBLIGATORIO = 'TR_ERROR_PASSWORD_OBLIGATORIO';
export const TR_ERROR_FIELD_EMAIL = 'TR_ERROR_FIELD_EMAIL';
export const TR_ERROR_FIELD_PASSWORD = 'TR_ERROR_FIELD_PASSWORD';
export const TR_CERRAR = 'TR_CERRAR';
export const TR_ENTRAR = 'TR_ENTRAR';
export const TR_ACCESO_CUENTA = 'TR_ACCESO_CUENTA';
export const TR_FORM_EMAIL = 'TR_FORM_EMAIL';
export const TR_FORM_CONTRASENYA = 'TR_FORM_CONTRASENYA';
export const TR_LOGIN_ERROR = 'TR_LOGIN_ERROR';
export const TR_OLVIDADO_CONTRASENYA = 'TR_OLVIDADO_CONTRASENYA';
export const TR_TE_ECHAMOS_UN_CABLE = 'TR_TE_ECHAMOS_UN_CABLE';
export const TR_NO_TIENES_CUENTA = 'TR_NO_TIENES_CUENTA';
export const TR_REGISTRATE_AQUI = 'TR_REGISTRATE_AQUI';
export const TR_REESTABLECER_CLAVE = 'TR_REESTABLECER_CLAVE';
export const TR_INDICA_DIRECCION = 'TR_INDICA_DIRECCION';
export const TR_ENVIAR = 'TR_ENVIAR';
export const TR_ERROR_RECORDAR_CONTRASENYA = 'TR_ERROR_RECORDAR_CONTRASENYA';
export const TR_ENVIADO_EMAIL_RECORDAR_CONTRASENYA = 'TR_ENVIADO_EMAIL_RECORDAR_CONTRASENYA';
export const TR_VOLVER_AL_LISTADO = 'TR_VOLVER_AL_LISTADO';
export const TR_PRODUCTO_NO_ENCONTRADO = 'TR_PRODUCTO_NO_ENCONTRADO';
export const TR_DESCARGAR_PDF = 'TR_DESCARGAR_PDF';
export const TR_INFORMACION = 'TR_INFORMACION';
export const TR_VERSIONES_PRODUCTOS = 'TR_VERSIONES_PRODUCTOS';
export const TR_VERSIONES_PRODUCTOS_DESCRIPCION = 'TR_VERSIONES_PRODUCTOS_DESCRIPCION';
export const TR_GRAFICA_PRODUCTO = 'TR_GRAFICA_PRODUCTO';
export const TR_GRAFICA_PRODUCTO_SUBTITULO = 'TR_GRAFICA_PRODUCTO_SUBTITULO';
export const TR_GRAFICA_PRODUCTO_DESCRIPCION = 'TR_GRAFICA_PRODUCTO_DESCRIPCION';
export const TR_VALOR_LLENADO = 'TR_VALOR_LLENADO';
export const TR_VALOR_LLENADO_SUBTITULO = 'TR_VALOR_LLENADO_SUBTITULO';
export const TR_VALOR_LLENADO_DESCRIPCION = 'TR_VALOR_LLENADO_DESCRIPCION';
export const TR_ANCHO = 'TR_ANCHO';
export const TR_ALTO = 'TR_ALTO';
export const TR_REFERENCIA = 'TR_REFERENCIA';
export const TR_VOLVER = 'TR_VOLVER';
export const TR_GUARDAR_PROYECTO = 'TR_GUARDAR_PROYECTO';
export const TR_MIS_PROYECTOS = 'TR_MIS_PROYECTOS';
export const TR_NOMBRE_NUEVO_PROYECTO = 'TR_NOMBRE_NUEVO_PROYECTO';
export const TR_NOMBRE_NUEVA_CARPETA = 'TR_NOMBRE_NUEVA_CARPETA';
export const TR_PROYECTO = 'TR_PROYECTO';
export const TR_SELECCIONAR = 'TR_SELECCIONAR';
export const TR_SELECCIONAR_PROYECTO = 'TR_SELECCIONAR_PROYECTO';
export const TR_CARPETA = 'TR_CARPETA';
export const TR_LONGITUD = 'TR_LONGITUD';
export const TR_OBSERVACIONES = 'TR_OBSERVACIONES';
export const TR_TITULO_GUARDAR_PROYECTO = 'TR_TITULO_GUARDAR_PROYECTO';
export const TR_DESCRIPCION_GUARDAR_PROYECTO = 'TR_DESCRIPCION_GUARDAR_PROYECTO';
export const TR_GUARDAR = 'TR_GUARDAR';
export const TR_GUARDAR_PRODUCTO_ERROR = 'TR_GUARDAR_PRODUCTO_ERROR';
export const TR_GUARDAR_PRODUCTO_ERROR_NO_CARPETAS = 'TR_GUARDAR_PRODUCTO_ERROR_NO_CARPETAS';
export const TR_GUARDADO = 'TR_GUARDADO';
export const TR_ASK_FOR_BUDGET = 'TR_ASK_FOR_BUDGET';
export const TR_ASKED = 'TR_ASKED';
export const TR_VIEW_LEGEND = 'TR_VIEW_LEGEND';
export const TR_ICON_IN = 'TR_ICON_IN';
export const TR_ICON_OUT = 'TR_ICON_OUT';
export const TR_ICON_IN_OUT = 'TR_ICON_IN_OUT';
export const TR_ICON_INDUSTRY = 'TR_ICON_INDUSTRY';
export const TR_ICON_FOOD = 'TR_ICON_FOOD';
export const TR_COOKIES_TEXTO = 'TR_COOKIES_TEXTO';
export const TR_COOKIES_ACEPTAR = 'TR_COOKIES_ACEPTAR';
export const TR_COOKIES_LEER_MAS = 'TR_COOKIES_LEER_MAS';
export const TR_COOKIES_URL = 'TR_COOKIES_URL';
export const TR_AVISO_LEGAL = 'TR_AVISO_LEGAL';
export const TR_TEXTO_LEGAL_TITLE = 'TR_TEXTO_LEGAL_TITLE';
export const TR_TEXTO_LEGAL = 'TR_TEXTO_LEGAL';
export const TR_MORE = 'TR_MORE';
export const TR_APELLIDOS = 'TR_APELLIDOS';
export const TR_SPAIN = 'TR_SPAIN';
export const TR_FRANCE = 'TR_FRANCE';
export const TR_ENGLAND = 'TR_ENGLAND';
export const TR_MOROCCO = 'TR_MOROCCO';
