import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";

interface TaskLoginRequest {
    username: string,
    password: string,
}

export interface TaskLoginResponse {
    token: string
}

export default class TaskLogin extends BaseGenericRequest<TaskLoginRequest, TaskLoginResponse> {

    private readonly username: string;
    private readonly password: string;

    public constructor(username: string, password: string) {
        super(METHOD.METHOD_POST, Urls.URL_LOGIN);
        this.username = username;
        this.password = password;
    }

    protected getRequest(): TaskLoginRequest {
        return {
            username: this.username,
            password: this.password,
        };
    }
}