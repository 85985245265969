import cookie from "react-cookies";
import i18next from "i18next"
import {generateRoute} from "./Router";
import {ROUTE_HOME} from "../routing/Routes";

export default class AuthManager {

    private static AUTH_TOKEN = 'AUTH_TOKEN';

    private static authToken: string;
    private static expires: Date;

    public static initialized(){
        this.authToken = cookie.load(this.AUTH_TOKEN);
    }

    public static getAuthToken(): string {
        return this.authToken;
    }

    public static login(authToken: string) {
        this.authToken = authToken;
        this.expires = new Date();
        let expires = this.expires.getHours();
        expires += 12;
        this.expires.setHours( expires );
        cookie.save(this.AUTH_TOKEN, this.authToken, { path: '/', expires: this.expires });
    }

    public static logout() {
        cookie.save('AUTH_TOKEN', '', { path: '/', expires: 0 });
        window.location.href = generateRoute(ROUTE_HOME, {locale: i18next.language});
    }

    public static isLogged(): boolean {
        return this.authToken !== null && this.authToken !== undefined && this.authToken !== '';
    }

};