import React from 'react';
import {Component, ReactNode} from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import RegisterFormReducer from './RegisterFormReducer';
import { validate } from './ValidateForm';
import {RegisterFormExtProps, RegisterFormProps, InputData} from "./interfaces";
import RenderField from '../../base/form/RenderField';
import TaskRegister, {TaskRegisterRequest, TaskRegisterResponse} from "../../ws/register/TaskRegister";
import RenderError from "../../base/form/RenderError";
import AuthManager from "../../utils/AuthManager";
import LoadingView from "../../base/loading/LoadingView";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_FORM_CONTRASENYA,
    TR_FORM_EMAIL,
    TR_HE_LEIDO_Y_ACEPTO,
    TR_NOMBRE, TR_APELLIDOS, TR_POLITICA_PRIVACIDAD, TR_REGISTRARME,
    TR_URL_POLITICA_PRIVACIDAD
} from "../../I18n/constants";
import {ReducerBuilder} from "co-redux-builders";

const mapStateToPropsGeneric = (state: any) => {
    return {
        formValues: state.form.DataForm,
    }
};

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(mapStateToPropsGeneric, RegisterFormReducer.autoMapToProps());

class RegisterForm extends Component<RegisterFormProps & typeof mapStateToProps & RegisterFormExtProps> {

    public state = { error: '', waiting: false };

    private onSubmit = (values: InputData) => {
        this.setState({error: '', waiting: true} );
        new TaskRegister({ email: values.email, plainPassword: values.password, name: values.name, lastName: values.lastName, } as TaskRegisterRequest)
            .onSuccess((response: TaskRegisterResponse) => {
                AuthManager.login(response.token);
                this.props.onRegister();
            })
            .onFail((error) => {
                this.setState({error: JSON.parse(error).message, waiting: false});
            })
            .execute();
    }

    public render(): ReactNode {
        return (
            <>
                <div className={`error-message mb-3 ${this.state.error === '' ? 'd-none' : ''}`}>{this.state.error}</div>
                <LoadingView loading={this.state.waiting}/>
                <form onSubmit={ this.props.handleSubmit(this.onSubmit) }>
                    <Field name="name" component={RenderError}/>
                    <Field name="email" component={RenderError}/>
                    <Field name="password" component={RenderError}/>
                    <Field name="legal" component={RenderError}/>
                    <div className="form-group">
                        <label htmlFor="DiameterInput">{ I18nUtils.tr(TR_NOMBRE) }</label>
                        <Field name="name" type="text" id="Name" component={RenderField}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="DiameterInput">{ I18nUtils.tr(TR_APELLIDOS) }</label>
                        <Field name="lastName" type="text" id="LastName" component={RenderField}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="DiameterInput">{ I18nUtils.tr(TR_FORM_EMAIL) }</label>
                        <Field name="email" type="text" id="Email" component={RenderField}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="DiameterInput">{ I18nUtils.tr(TR_FORM_CONTRASENYA) }</label>
                        <Field name="password" type="password" id="Pass" component={RenderField}/>
                    </div>
                    <div className="form-group form-check mt-4">
                        <Field name="legal" type="checkbox" id="lopdCheck" component={RenderField}/>
                        <label className="form-check-label" htmlFor="lopdCheck">
                            { I18nUtils.tr(TR_HE_LEIDO_Y_ACEPTO) }
                            <a href={ I18nUtils.tr(TR_URL_POLITICA_PRIVACIDAD) } target="_blank"> { I18nUtils.tr(TR_POLITICA_PRIVACIDAD) }</a>.
                        </label>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <button type="submit" className="btn btn-lg btn-primary">{ I18nUtils.tr(TR_REGISTRARME) }</button>
                    </div>
                </form>
            </>
        );

    }

};

export default reduxForm<InputData, RegisterFormExtProps>({
    validate,
    form: 'RegisterForm',
})(connect(mapStateToProps)(RegisterForm)as any);














