import * as React from 'react';
import i18next from "i18next";
import {Component, ReactNode} from 'react';
import {Link} from "react-router-dom";
import LoadingView from "../../base/loading/LoadingView";
import {ROUTE_PRODUCTO} from "../../routing/Routes";
import { ProductFolder, Proyecto} from "../../model/Proyecto";
import {generateRoute} from "../../utils/Router";
import Config from "../../Config";
import TaskDeleteProduct from "../../ws/proyectos/TaskDeleteProduct";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_LONGITUD, TR_OBSERVACIONES} from "../../I18n/constants";

interface ProductosProps {
    productos: ProductFolder[],
    actualizaEstructura: (proyectos: Proyecto[]) => void
}

class Productos extends Component<ProductosProps> {

    public state = { deletingProducto: 0};

    public eliminaProducto(id: number): void {
        this.setState({deletingProducto: id});
        new TaskDeleteProduct(id)
            .onSuccess((projects) => {
                this.setState({projects, loadingNew: false, nuevaCarpeta: ''});
                this.props.actualizaEstructura(projects)
            })
            .onFail(() => this.setState({loading: false}))
            .execute()
    }


    public render(): ReactNode {

        return (
            <div className="listado-productos-proyecto">
                {
                    this.props.productos.map((product) => {
                        return (
                            <div key={product.id}>
                                <LoadingView loading={this.state.deletingProducto === product.id}/>
                                    <div className="media">
                                        <Link to={generateRoute(ROUTE_PRODUCTO, {producto: product.product.ref, locale: i18next.language})}>
                                            <img src={Config.BASE_IMAGENES_PRODUCTOS + product.product.imagen} className="img-fluid"/>
                                        </Link>
                                        <div className="media-body align-self-center">
                                            <Link to={generateRoute(ROUTE_PRODUCTO, {producto: product.product.ref, locale: i18next.language})}>
                                                <span>{product.product.ref}</span> - {product.product.name} ({product.product.width}x{product.product.height})
                                                {product.longitude ? <><br/><strong>{I18nUtils.tr(TR_LONGITUD)}:</strong> {product.longitude}</> : ''}
                                                {product.observations ? <><br/><strong>{I18nUtils.tr(TR_OBSERVACIONES)}:</strong> {product.observations}</> : ''}
                                            </Link>
                                        </div>
                                        <div className="align-self-center">
                                            <i className="material-icons delete" onClick={() => this.eliminaProducto(product.id)}>delete</i>
                                        </div>
                                    </div>
                            </div>
                        );
                    })
                }
            </div>
        );

    }

}

export default Productos;