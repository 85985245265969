import {ActionBuilder} from "co-redux-builders";

export default ActionBuilder.build({

    closeModals: () => ({}),
    openLogin: () => ({}),
    openRegister: () => ({}),
    openPasswordLost: () => ({}),
    openLegendIcons: () => ({}),
    openModalLegal: () => ({}),

});