import {METHOD} from "co-generic-request";
import i18next from "i18next"
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {Proyecto} from "../../model/Proyecto";
import {generateRoute} from "../../utils/Router";

export default class TaskGetProjects extends BaseGenericRequest<{}, Proyecto[]> {

    public constructor() {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_GET_PROJECTS, {locale: i18next.language}));
    }
}