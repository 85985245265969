import {METHOD} from "co-generic-request";
import i18next from "i18next";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {generateRoute} from "../../utils/Router";

export default class TaskAskForBudgetProject extends BaseGenericRequest<{}, {}> {

    public constructor(id: number) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_ASK_FOR_BUDGET_PROJECT, {project: id, locale: i18next.language}));
    }

}