import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {Proyecto} from "../../model/Proyecto";
import i18next from "i18next"
import {generateRoute} from "../../utils/Router";

export default class TaskCreateProject extends BaseGenericRequest<{}, Proyecto[]> {

    private readonly name: string;

    public constructor(name: string) {
        super(METHOD.METHOD_POST,  generateRoute(Urls.URL_CREATE_PROJECT, {locale: i18next.language}));
        this.name = name;
    }

    protected getRequest(): any {
        return {project_form: { name: this.name }};
    }

}