import * as React from 'react';
import {ReactNode} from 'react';
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_CERRAR,
    TR_PROYECTO,
    TR_CARPETA,
    TR_SELECCIONAR,
    TR_GUARDAR,
    TR_GUARDAR_PRODUCTO_ERROR,
    TR_TITULO_GUARDAR_PROYECTO,
    TR_DESCRIPCION_GUARDAR_PROYECTO,
    TR_PROYECTOS, TR_SELECCIONAR_PROYECTO, TR_GUARDAR_PRODUCTO_ERROR_NO_CARPETAS, TR_LONGITUD, TR_OBSERVACIONES,
} from "../../I18n/constants";
import LoadingView from "../../base/loading/LoadingView";
import Modal from "react-bootstrap/Modal";
import BaseModal, {BaseModalProps} from "../../base/modal/BaseModal";
import TaskGetProjects from "../../ws/proyectos/TaskGetProjects";
import {Proyecto} from "../../model/Proyecto";
import {Link} from "react-router-dom";
import {ROUTE_PROYECTOS} from "../../routing/Routes";
import TaskAddProduct from "../../ws/proyectos/TaskAddProduct";

interface ModalAsignarProyectoProps {
    idProducto: number,
    productoGuardado: () => void,
}

class ModalAsignarProyecto extends BaseModal<ModalAsignarProyectoProps & BaseModalProps> {

    public state = { loading: true, error: false, proyecto: "", carpeta: "", longitud: "", observaciones: "", proyectos: [] as Proyecto[] };

    public componentWillMount(): void {
        new TaskGetProjects()
            .onSuccess((proyectos) => this.setState({proyectos, loading: false}))
            .onFail(() => this.props.onClose() )
            .execute()
    }

    public asignarProyecto() {
        if (this.state.carpeta !== "") {
            this.setState({loading: true});
            new TaskAddProduct(parseInt(this.state.carpeta, 0), this.props.idProducto, this.state.longitud, this.state.observaciones)
                .onSuccess(() => { this.props.onClose(); this.props.productoGuardado(); this.setState({loading: false}) })
                .onFail(() => this.setState({error: true, loading: false}))
                .execute();
        }
    }

    public renderSelectorProyecto(): ReactNode {
        return (
            <select value={this.state.proyecto} className="form-control" onChange={(e) => {this.setState({proyecto: e.target.value, carpeta: ""})}}>
                <option value="">{I18nUtils.tr(TR_SELECCIONAR)}</option>
                {
                    this.state.proyectos.map((proyecto) => {
                        return <option key={proyecto.id} value={proyecto.id}>{proyecto.name}</option>
                    })
                }
            </select>
        );
    }

    public renderSelectorCarpeta(): ReactNode {
        const proyectoSeleccionado = this.state.proyectos.find((proyecto) => proyecto.id === parseInt(this.state.proyecto, 0));
        if (proyectoSeleccionado) {
            if (proyectoSeleccionado.folders.length === 0) {
                return <div className="error-message">{I18nUtils.tr(TR_GUARDAR_PRODUCTO_ERROR_NO_CARPETAS)}</div>
            }
            return (
                <select value={this.state.carpeta} className="form-control" onChange={(e) => {
                    this.setState({carpeta: e.target.value})
                }}>
                    <option value="">{I18nUtils.tr(TR_SELECCIONAR)}</option>
                    {
                        proyectoSeleccionado.folders.map((carpeta) => {
                            return <option key={carpeta.id} value={carpeta.id}>{carpeta.name}</option>
                        })
                    }
                </select>
            );
        }
        return <p className="error-message">{I18nUtils.tr(TR_SELECCIONAR_PROYECTO)}</p>
    }

    public render(): ReactNode {
        return (
            <Modal id="modal-asignar-proyecto" show={this.props.show} className="modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label={ I18nUtils.tr(TR_CERRAR) } onClick={() => this.props.onClose()}>
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="container no-gutters">
                            <div className="row">
                                <div className="col-12 modal-form">
                                    <h2 className="modal-title">{ I18nUtils.tr(TR_TITULO_GUARDAR_PROYECTO) }</h2>
                                    <p className="lead text-muted mb-4">
                                        { I18nUtils.tr(TR_DESCRIPCION_GUARDAR_PROYECTO) }
                                        <Link to={ROUTE_PROYECTOS}> {I18nUtils.tr(TR_PROYECTOS).toLowerCase()}</Link>.
                                    </p>
                                    <div className={`error-message mb-3 ${this.state.error ? '' : 'd-none'}`}>
                                        { I18nUtils.tr(TR_GUARDAR_PRODUCTO_ERROR) }
                                    </div>
                                    <LoadingView loading={this.state.loading}/>
                                    <form onSubmit={ (e) => { e.preventDefault(); this.asignarProyecto() } }>
                                        <div className="form-group">
                                            <label>{ I18nUtils.tr(TR_PROYECTO) }</label>
                                            { this.renderSelectorProyecto() }
                                        </div>
                                        <div className="form-group">
                                            <label>{ I18nUtils.tr(TR_CARPETA) }</label>
                                            { this.renderSelectorCarpeta() }
                                        </div>
                                        <div className="form-group">
                                            <label>{ I18nUtils.tr(TR_LONGITUD) }</label>
                                            <input className="form-control" type="text" value={this.state.longitud} onChange={(e) => this.setState({longitud: e.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label>{ I18nUtils.tr(TR_OBSERVACIONES) }</label>
                                            <textarea className="form-control" onChange={(e) => this.setState({observaciones: e.target.value})} value={this.state.observaciones}/>
                                        </div>
                                        <div className={`justify-content-center mt-2 ${this.state.carpeta === "" ? 'd-none' : 'd-flex'}`}>
                                            <button type="submit" className="btn btn-lg btn-primary">{ I18nUtils.tr(TR_GUARDAR) }</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

}

export default ModalAsignarProyecto;

