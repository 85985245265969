import * as React from 'react';
import {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import BuscadorReducer from "./BuscadorReducer";
import Form from "./Form/Form";
import Resultados from "./Resultados/Resultados";
import {InputValuesBuscador} from "./Form/interfaces";
import LoadingView from "../../base/loading/LoadingView";
import TaskGetMeasures from "../../ws/measures/TaskGetMeasures";
import TaskSearch from "../../ws/products/TaskSearch";
import {Buscador} from "../../model/Buscador";
import {Cable} from "../../model/Cable";
import TaskGetHeightProduct from "../../ws/products/TaskGetHeightProduct";

const mapStateToProps = BuscadorReducer.autoMapToProps();

class BuscadorComponent extends Component<typeof mapStateToProps> {

    public componentWillMount(): void {
        new TaskGetMeasures().execute();
        new TaskGetHeightProduct().execute();

        const initialData = this.getInitialValues();
        if (initialData.cables.length > 0) {
            new TaskSearch(this.generateData(initialData)).execute();
        }

    }

    public generateData(values: InputValuesBuscador): Buscador {
        const cables = values.cables.map((cable) => {
            return {id: cable[0], cantidad: cable[1]} as Cable;
        });

        return {
            cables,
            tipoBandeja: values.tipoBandeja,
            ampliacion: values.ampliacion,
            altura: values.altura
        } as Buscador;
    }

    public getInitialValues(): InputValuesBuscador {
        const cables = [] as any;
        this.props.buscador.cables.map((cable) => {
            cables.push([cable.id, cable.cantidad]);
        });

        return {
            cables,
            tipoBandeja: this.props.buscador.tipoBandeja,
            ampliacion: this.props.buscador.ampliacion,
            altura: this.props.buscador.altura
        } as InputValuesBuscador;
    }

    public render(): ReactNode {

        return (
            <div>
                <LoadingView loading={this.props.buscando || this.props.medidas.length === 0}/>
                <div className="wrapper-buscador">
                    <header className="masthead">
                        <div className="container">
                            <div id="filter" className="card">
                                <div className="card-body">
                                    <Form initialValues={this.getInitialValues()}/>
                                </div>
                            </div>
                        </div>
                    </header>
                    <Resultados/>
                </div>
            </div>
        );

    }

}

export default connect(mapStateToProps)(BuscadorComponent) as React.ComponentType<{}>;