import Config from "../Config";

export default class StateManager {

    public static FILTROS = 'FILTROS';

    private static KEY = "STATE_";
    private static DEFAULT_STATE = {};

    public static getState(key: string, defaultValues: object | null = null): object {
        const item = localStorage.getItem(this.KEY + key);
        if (item) {
            const itemLocalStorage: any = JSON.parse(item);
            if (itemLocalStorage.version === Config.VERSION) {
                return itemLocalStorage;
            }
        }
        if (!defaultValues) {
            return this.DEFAULT_STATE;
        }
        return {...this.DEFAULT_STATE, ...defaultValues}
    }

    public static saveState(key: string, value: object = {}) {
        localStorage.setItem(this.KEY + key, JSON.stringify(value));
    }

    public static clear() {
        localStorage.clear();
    }


}