import React from 'react';
import {Component} from 'react';

interface ProductoValorLlenadoGraficaProps {
    width: number,
    height: number,
    porcenjeRelleno: number
}

interface Coordenada {
    x: number,
    y: number,
}

class ProductoValorLlenadoGrafica extends Component<ProductoValorLlenadoGraficaProps> {

    public canvasRef = React.createRef<HTMLCanvasElement>();
    public width = 660;
    public height = 150;

    public componentDidMount(): void {
        this.draw();
    }

    public draw() {

        if (this.canvasRef.current) {

            const ctx = this.canvasRef.current.getContext('2d');

            if (ctx) {

                const desdeX: number = ( this.width - this.props.width ) / 2;
                const desdeY: number = this.height - 40 - this.props.height;

                // ...............
                // ..a.........f..
                // ...............
                // ...............
                // ..b.........e..
                // ....c.....d....

                const a = { x: desdeX, y: desdeY } as Coordenada;
                const b = { x: desdeX, y: desdeY + this.props.height - 5 } as Coordenada;
                const c = { x: desdeX + 3, y: desdeY + this.props.height } as Coordenada;
                const d = { x: desdeX + this.props.width - 3, y: desdeY + this.props.height } as Coordenada;
                const e = { x: desdeX + this.props.width, y: desdeY + this.props.height - 5 } as Coordenada;
                const f = { x: desdeX + this.props.width, y: desdeY } as Coordenada;

                ctx.lineWidth = 1;
                ctx.strokeStyle = '#495057';

                ctx.moveTo(a.x, a.y);
                ctx.lineTo(b.x, b.y);
                ctx.quadraticCurveTo(b.x, b.y, c.x, c.y);
                ctx.lineTo(d.x, d.y);
                ctx.quadraticCurveTo(d.x, d.y, e.x, e.y);
                ctx.lineTo(f.x, f.y);
                ctx.stroke();

                const desdeRellenoY = desdeY + this.props.height - (this.props.porcenjeRelleno > 100 ? 100 : this.props.porcenjeRelleno) * this.props.height / 100;
                ctx.beginPath();
                ctx.fillStyle = "#D8262E";
                ctx.moveTo(a.x, desdeRellenoY);
                ctx.lineTo(b.x, b.y);
                ctx.quadraticCurveTo(b.x, b.y, c.x, c.y);
                ctx.lineTo(d.x, d.y);
                ctx.quadraticCurveTo(d.x, d.y, e.x, e.y);
                ctx.lineTo(f.x, desdeRellenoY);
                ctx.closePath();
                ctx.fill();

                ctx.font = "25px Arial";
                ctx.textAlign = "center";
                ctx.fillStyle = "#000";
                ctx.fillText(this.props.width + " mm", this.width / 2, this.height - 7);
                ctx.save();

                ctx.rotate(-Math.PI / 2);
                ctx.fillText(this.props.height + " mm", -(desdeY + this.props.height / 2), desdeX - 10);

            }

        }

    }

    public render() {
        return (
            <>
                <div className="progress" data-percentage={this.props.porcenjeRelleno > 100 ? 100 : this.props.porcenjeRelleno}>
                    <span className="progress-left">
                        <span className="progress-bar"></span>
                    </span>
                    <span className="progress-right">
                        <span className="progress-bar"></span>
                    </span>
                    <div className="progress-value">
                        <div>{this.props.porcenjeRelleno}%</div>
                    </div>
                </div>
                <canvas className="canvasValorLlenado" ref={ this.canvasRef } width={this.width} height={this.height}/>
            </>
        );
    }

}

export default ProductoValorLlenadoGrafica;














