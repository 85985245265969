import * as React from 'react';
import {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import i18next from "i18next"
import BuscadorReducer from "../BuscadorReducer";
import {Link} from "react-router-dom";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ALTO,
    TR_ANCHO,
    TR_PAG_ANTERIOR,
    TR_PAG_SIGUIENTE,
    TR_REFERENCIA,
    TR_RESULTADOS_ENCONTRADOS,
    TR_RESULTADOS_ENCONTRADOS_NINGUNO,
    TR_TIPO_BANDEJA_CIEGA,
    TR_TIPO_BANDEJA_PERFORADA,
    TR_TIPO_BANDEJA_REJILLA,
    TR_VIEW_LEGEND
} from "../../../I18n/constants";
import Config from "../../../Config";
import {generateRoute} from "../../../utils/Router";
import {ROUTE_PRODUCTO} from "../../../routing/Routes";
import ModalesActions from "../../Modales/ModalesActions";

const mapStateToProps = BuscadorReducer.autoMapToProps();

class Resultados extends Component<typeof mapStateToProps> {

    private productosPagina: number = 10000;

    public state = {currentPage: 1};

    public componentDidMount(): void {
        if (this.props.resultados && this.props.resultados.productos && Object.keys(this.props.resultados.productos).length > 0) {
            const searchResultsDiv: HTMLElement | null = document.getElementById("search-results");
            if (!!searchResultsDiv) {
                window.scrollTo({behavior: 'smooth', top: searchResultsDiv.offsetTop});
            }
        }
    }

    public componentDidUpdate(prevProps: Readonly<typeof mapStateToProps>, prevState: Readonly<{}>, snapshot?: any): void {

        if (this.props.buscando && !prevProps.buscando) {
            this.setState({currentPage: 1})
        }

        else if (this.props.resultados !== prevProps.resultados) {
            const searchResultsDiv: HTMLElement | null = document.getElementById("search-results");
            if (!!searchResultsDiv) {
                window.scrollTo({behavior: 'smooth', top: searchResultsDiv.offsetTop});
            }
        }
    }

    public renderPaginador(totalResultados: number): ReactNode {

        if (totalResultados > this.productosPagina) {
            const totalPaginas = Math.floor(totalResultados / this.productosPagina);

            const items: ReactNode[] = [];

            for (let i = 1; i <= totalPaginas; i++) {
                items.push(
                    <li className={`page-item ${this.state.currentPage === i ? 'active' : ''}`} key={i}>
                        <a className="page-link" onClick={() => this.setState({currentPage: i})}>{i}</a>
                    </li>
                );
            }

            return (
                <nav>
                    <ul className="pagination">
                        <li className={`page-item ${this.state.currentPage === 1 ? 'disabled' : ''}`}>
                            <a className="page-link" onClick={() => this.setState({currentPage: 1})}>
                                {I18nUtils.tr(TR_PAG_ANTERIOR)}
                            </a>
                        </li>
                        {items}
                        <li className={`page-item ${this.state.currentPage === totalPaginas ? 'disabled' : ''}`}>
                            <a className="page-link"
                               onClick={() => this.setState({currentPage: this.state.currentPage + 1})}>
                                {I18nUtils.tr(TR_PAG_SIGUIENTE)}
                            </a>
                        </li>
                    </ul>
                </nav>
            );
        }
        return <></>

    }

    public renderTipoProducto(tipo: string): string {
        let tipoDescripcion = '';
        switch (tipo) {
            case 'rejilla':
                tipoDescripcion = TR_TIPO_BANDEJA_REJILLA;
                break;
            case 'ciega':
                tipoDescripcion = TR_TIPO_BANDEJA_CIEGA;
                break;
            case 'perforada':
                tipoDescripcion = TR_TIPO_BANDEJA_PERFORADA;
                break;
        }
        return I18nUtils.tr(tipoDescripcion);
    }

    public openLengendIcons(e) {
        e.stopPropagation();
        e.preventDefault();
        ModalesActions.openLegendIcons.asConnectedAction();
    }

    public renderResultados(): ReactNode {

        let i = 0;
        const desde = this.productosPagina * (this.state.currentPage - 1) + 1;
        const hasta = this.productosPagina * this.state.currentPage;
        return Object.keys(this.props.resultados.productos).map((index) => {
            i++;
            if (i < desde || i > hasta) {
                return null
            }
            const producto = this.props.resultados.productos[index][0];
            return (
                <Link to={generateRoute(ROUTE_PRODUCTO, {producto: producto.info.ref, locale: i18next.language})}
                      key={index}>
                    <div className="product-card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-lg-7">
                                    <div className="media">
                                        <div className="img-results">
                                            <img src={Config.BASE_IMAGENES_PRODUCTOS + producto.info.imagen}
                                                 className="img-fluid"/><br/>
                                            <span className="btn-open-lengend-icons" onClick={(e) => {
                                                this.openLengendIcons(e)
                                            }}>
                                                {I18nUtils.tr(TR_VIEW_LEGEND)}
                                            </span>
                                        </div>
                                        <div className="media-body">
                                            <h2 className="product-name">{producto.info.name}</h2>
                                            <h3 className="product-type">
                                                {this.renderTipoProducto(producto.info.type)}<br/>
                                                <span className="text-muted small">{producto.info.norma}</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-5">
                                    <table className="table tablaVersiones">
                                        <tbody>
                                        <tr>
                                            <th scope="row">{I18nUtils.tr(TR_REFERENCIA)}</th>
                                            <th>{I18nUtils.tr(TR_ANCHO)}</th>
                                            <th>{I18nUtils.tr(TR_ALTO)}</th>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                                {producto.info.ref}
                                            </td>
                                            <td>{producto.info.width}</td>
                                            <td>{producto.info.height}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            )
        })

    }

    public render(): ReactNode {

        if (this.props.buscador.tipoBandeja !== '' && this.props.resultados.productos) {

            const totalResultados = Object.keys(this.props.resultados.productos).length;

            return (
                <section id="search-results">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {
                                    totalResultados > 0 ?
                                        <>
                                            <p className="lead">{I18nUtils.tr(TR_RESULTADOS_ENCONTRADOS).replace(':total', totalResultados.toString())}</p>
                                            {this.renderResultados()}
                                            {this.renderPaginador(totalResultados)}
                                        </> :
                                        <div className="product-card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p className="lead" style={{marginBottom: 0}}
                                                           dangerouslySetInnerHTML={{__html: I18nUtils.tr(TR_RESULTADOS_ENCONTRADOS_NINGUNO)}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            );

        }

        return <></>

    }

}

export default connect(mapStateToProps)(Resultados) as React.ComponentType<{}>;