import * as React from 'react';
import { ReactNode} from 'react';
import RegisterForm from './RegisterForm'
import AuthManager from "../../utils/AuthManager";
import BaseModal, {BaseModalProps} from "../../base/modal/BaseModal";
import Modal from "react-bootstrap/Modal";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_CERRAR,
    TR_CREA_UNA_CUENTA,
    TR_ENTRA_Y_CONSULTA,
    TR_ENTRAR,
    TR_REGISTRATE,
    TR_YA_TIENES_CUENTA
} from "../../I18n/constants";

interface RegisterScreenProps {
    openLogin: () => void
}

class RegisterScreen extends BaseModal<BaseModalProps & RegisterScreenProps> {

    public componentDidMount(): void {
        if (AuthManager.isLogged()) {
            this.props.onClose();
        }
    }

    public render(): ReactNode {
        return (
            <Modal id="SignUpModal" show={this.props.show} className="modal" dialogClassName="modal-xl">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label={ I18nUtils.tr(TR_CERRAR) } onClick={() => this.props.onClose()}>
                        <i className="material-icons">close</i>
                    </button>
                </div>
                <div className="modal-body p-0">
                    <div className="container no-gutters">
                        <div className="row">
                            <div className="col-12 col-lg-5 modal-bg p-0">
                                <div className="overlay">
                                    <h2 className="modal-title" id="SignUpModal">{ I18nUtils.tr(TR_YA_TIENES_CUENTA) }</h2>
                                    <p className="lead">{ I18nUtils.tr(TR_ENTRA_Y_CONSULTA) }</p>
                                    <a className="btn btn-white btn-lg" onClick={() => { this.props.openLogin(); }}>{ I18nUtils.tr(TR_ENTRAR) }</a>
                                </div>
                            </div>
                            <div className="col-12 col-lg-7 modal-form">
                                <h2 className="modal-title">{ I18nUtils.tr(TR_REGISTRATE) }</h2>
                                <p className="lead text-muted mb-4">{ I18nUtils.tr(TR_CREA_UNA_CUENTA) }</p>
                                <RegisterForm onRegister={ this.props.onClose }/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

}

export default RegisterScreen;

