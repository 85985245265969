import * as React from 'react';
import {ReactNode} from 'react';
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_NO_TIENES_CUENTA, TR_LOGIN_ERROR,
    TR_REGISTRATE_AQUI, TR_TE_ECHAMOS_UN_CABLE, TR_FORM_CONTRASENYA,
    TR_ENTRA_Y_CONSULTA, TR_ACCESO_CUENTA, TR_FORM_EMAIL, TR_OLVIDADO_CONTRASENYA, TR_CERRAR, TR_ENTRAR,
} from "../../I18n/constants";
import LoginReducer from "./LoginReducer";
import LoginActions from "./LoginActions";
import TaskLogin, {TaskLoginResponse} from "../../ws/login/TaskLogin";
import AuthManager from "../../utils/AuthManager";
import {connect} from "react-redux";
import LoadingView from "../../base/loading/LoadingView";
import Modal from "react-bootstrap/Modal";
import BaseModal, {BaseModalProps} from "../../base/modal/BaseModal";

const mapStateToProps = LoginReducer.autoMapToProps();
const mapActionsToProps = LoginActions.autoMapToProps();

interface ScreenHeaderProps {
    openRegister: () => void,
    openPasswordLost: () => void
}

class LoginScreen extends BaseModal<BaseModalProps & ScreenHeaderProps & typeof mapStateToProps & typeof mapActionsToProps> {

    public state = { loginError: false, waiting: false };

    public componentDidMount(): void {
        if (AuthManager.isLogged()) {
            this.props.onClose();
        }
    }

    public doLogin(): void {
        if (this.props.username !== '' && this.props.password !== '') {
            this.setState({waiting: true});
            new TaskLogin(this.props.username, this.props.password)
                .onSuccess((response: TaskLoginResponse) => {
                    AuthManager.login(response.token);
                    this.props.onClose();
                })
                .onFail(() => this.setState({loginError: true, waiting: false}))
                .execute();
        }
    }

    public render(): ReactNode {
        return (
            <Modal id="LoginModal" show={this.props.show} className="modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label={ I18nUtils.tr(TR_CERRAR) } onClick={() => this.props.onClose()}>
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="container no-gutters">
                            <div className="row">
                                <div className="col-12 modal-form">
                                    <h2 className="modal-title">{ I18nUtils.tr(TR_ACCESO_CUENTA) }</h2>
                                    <p className="lead text-muted mb-4">{ I18nUtils.tr(TR_ENTRA_Y_CONSULTA) }</p>
                                    <div className={`error-message mb-3 ${this.state.loginError ? '' : 'd-none'}`}>
                                        { I18nUtils.tr(TR_LOGIN_ERROR) }
                                    </div>
                                    <LoadingView loading={this.state.waiting}/>
                                    <form onSubmit={ (e) => { e.preventDefault(); this.doLogin() } }>
                                        <div className="form-group">
                                            <label htmlFor="DiameterInput">{ I18nUtils.tr(TR_FORM_EMAIL) }</label>
                                            <input type="email"
                                                   className="form-control"
                                                   value={this.props.username}
                                                   onChange={(e) => {this.setState({loginError: false}); this.props.setUsername(e.target.value)}}
                                                   onKeyPress={(e) => { if (e.key === 'Enter') { this.doLogin() } }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="DiameterInput">{ I18nUtils.tr(TR_FORM_CONTRASENYA) }</label>
                                            <input type="password" className="form-control"
                                                   aria-label={ I18nUtils.tr(TR_FORM_CONTRASENYA) } aria-describedby="see-pass"
                                                   value={this.props.password}
                                                   onChange={(e) => {this.setState({loginError: false}); this.props.setPassword(e.target.value)}}
                                                   onKeyPress={(e) => { if (e.key === 'Enter') { this.doLogin() } }}
                                            />
                                        </div>
                                        <p className="text-muted">
                                            { I18nUtils.tr(TR_OLVIDADO_CONTRASENYA) }<br/>
                                            <a onClick={ () => this.props.openPasswordLost() }>
                                                { I18nUtils.tr(TR_TE_ECHAMOS_UN_CABLE) }
                                            </a>
                                        </p>
                                        <div className="d-flex justify-content-center mt-2">
                                            <button type="submit" className="btn btn-lg btn-primary">{ I18nUtils.tr(TR_ENTRAR) }</button>
                                        </div>
                                    </form>
                                    <hr/>
                                    <p className="lead text-muted text-center mb-0">
                                        { I18nUtils.tr(TR_NO_TIENES_CUENTA) }<br/>
                                        <a data-toggle="modal" onClick={ () => this.props.openRegister() }>
                                            { I18nUtils.tr(TR_REGISTRATE_AQUI) }
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

}

export default connect(mapStateToProps, mapActionsToProps)(LoginScreen) as React.ComponentType<BaseModalProps & ScreenHeaderProps>;

