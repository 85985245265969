import * as React from 'react';
import {Component, ReactNode} from 'react';
import I18nUtils from "../../I18n/I18nUtils";
import {TR_AVISO_LEGAL, TR_DESARROLLADO_POR} from "../../I18n/constants";
import ModalesActions from "../../modules/Modales/ModalesActions";

class ScreenFooter extends Component {

    public render(): ReactNode {
        return (
            <footer className="small text-muted">
                <div className="container">
                    <p>© 2019 <a href="https://www.aiscan.com/" target="_blank">AISCAN, S.L.</a></p>
                    <p><a onClick={() => ModalesActions.openModalLegal.asConnectedAction()}>{I18nUtils.tr(TR_AVISO_LEGAL)}</a></p>
                    <p>{I18nUtils.tr(TR_DESARROLLADO_POR)} <a href="https://cuatroochenta.com" target="_blank">Cuatroochenta</a></p>
                </div>
            </footer>
        );
    }

}

export default ScreenFooter;

