import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import BaseGenericRequest from "../BaseGenericRequest";
import {Medida} from "../../model/Medida";

export default class TaskGetMeasures extends BaseGenericRequest<{}, Medida[]> {

    public constructor() {
        super(METHOD.METHOD_GET, Urls.URL_GET_MEASURES );
    }
}