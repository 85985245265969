import * as React from 'react';
import {Component, ReactNode} from 'react';
import {ClipLoader} from "react-spinners";

export interface LoadingProps {
    loading: boolean;
}

export default class LoadingView extends Component<LoadingProps> {

    public render(): ReactNode {
        if (!this.props.loading) {
            return null;
        }
        return (
            <div className="loadingView">
                <div className={'align-middle text-costcenter'}>
                    <ClipLoader
                        sizeUnit={"px"}
                        size={50}
                        color={'#EA5084'}
                        loading={this.props.loading}
                    />
                </div>
            </div>
        );
    }

}

